import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, Typography } from "@mui/material";
import {
  LoadScript,
  StandaloneSearchBox,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { PlaceRounded } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import { companyService } from "../app/services"; // Ensure this service is correctly set up
import { Rate } from "antd";

const libraries = ["places"];  // Define libraries array outside the component

const MapPicker = ({
  Lat,
  Lng,
  Address,
  selectedProfessionalType,
  handleViewScheduleClick
}) => {
  const [searchAddress, setSearchAddress] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  // const [businesses, setBusinesses] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [focusedMarker, setFocusedMarker] = useState(null);
  const [latitude, setLatitude] = useState(Lat);
  const [longitude, setLongitude] = useState(Lng);
  const [address, setAddress] = useState(Address);
  const [professionalType, setProfessionalType] = useState(selectedProfessionalType);
  const { t } = useTranslation();

  const [registeredProfessionals, setRegisteredProfessionals] = useState([]);
  const [detailedBusinesses, setDetailedBusinesses] = useState([]);
 // const [mergedBusinesses, setMergedBusinesses] = useState([]);
  
  const mapRef = useRef(null);
  const [lastFetchCenter, setLastFetchCenter] = useState({ lat: null, lng: null });
  const FETCH_THRESHOLD = 0.005; // Adjust this threshold as needed

  useEffect(() => {
    // Fetch professionals from your backend
    companyService
      .getCompaniesByCategoryAndLocationAndCompanyid(professionalType.name, { Lat: latitude, Lng: longitude })
      .then((company) => {
        console.log("company.reference");
        console.log(company[0].reference);
        setRegisteredProfessionals(company);   
      })
      .catch((error) => {
        console.error("Failed to fetch professionals:", error);
      });
  }, [professionalType, latitude, longitude]);

  const fetchNearbyBusinesses = (location) => {
    console.log("Location for nearby search:", location);
    if (location && mapRef.current) {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
  
      // Retrieve the current map bounds
      const bounds = mapRef.current.getBounds();
  
      service.nearbySearch(
        {
          location,
          radius: 1000,
          bounds: bounds,
          type: [professionalType.googleTypeName], // Adjust the type as needed
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // debugger;
            // const uniqueBusinesses = results.filter((business) => 
            //   !detailedBusinesses.some((prof) => prof.place_id === business.place_id)
            // );
          
            setDetailedBusinesses(results);
           // mergeRegisteredAndMapBusinesses();
          }

            // }
            // // Fetch detailed information for each business
            // results.forEach((business) => {
            //   service.getDetails(
            //     { placeId: business.place_id },
            //     (placeResult, placeStatus) => {
            //       if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
            //         setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
            //       }
            //     }
            //   );
            // });
          // } else {
          //   setDetailedBusinesses([]);
          // }
        }
      );
    }
  };

  const searchBusinessByAddress = (address) => {
    if (address && mapRef.current) {
      const service = new window.google.maps.places.PlacesService(mapRef.current);
  
      // Use textSearch to find the business by address
      service.textSearch(
        {
          query: address,
          location: mapRef.current.getCenter(), // Optional: prioritize results near the center of the map
          radius: 5000, // Adjust the radius if needed
        },
        (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results.length > 0) {
            const firstResult = results[0];
            const request = {
              placeId: firstResult.place_id,
              fields: ["name", "formatted_address", "place_id", "geometry","rating"],
            };
          
            service.getDetails(request, (place, status) => {
            // Now fetch detailed information for the first result
            // service.getDetails(
            //   {
            //     placeId: firstResult.place_id,
                // fields: [
                //   "place_id",
                //   "formatted_address",
                //   "formatted_phone_number",
                //   "international_phone_number",
                //   "rating",
                //   "user_ratings_total",
                //   "business_status",
                //   "website",
                //   "icon",
                //   "address_components",
                //   "geometry",
                //   "vicinity",
                //   "types"
                // ]
              //},
              // (placeResult, placeStatus) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  // Format the details and handle them as needed
                  return {
                    place_id: place.place_id,
                    formatted_address: place.formatted_address,
                    phone_number: place.formatted_phone_number || place.international_phone_number,
                    rating: place.rating,
                    total_ratings: place.user_ratings_total,
                    business_status: place.business_status,
                    website: place.website,
                    icon: place.icon,
                    address_components: place.address_components,
                    geometry: place.geometry,
                    vicinity: place.vicinity,
                    types: place.types,
                  };
                }
              }
            );
          } else {
            console.error("No business found or an error occurred:", status);
          }
        }
      );
    }
  };

  const handleBoundsChanged = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();
      const newLat = center.lat();
      const newLng = center.lng();
  
      // Check if the center has moved significantly from the last fetched location
      if (
        !lastFetchCenter.lat || 
        Math.abs(newLat - lastFetchCenter.lat) > FETCH_THRESHOLD || 
        Math.abs(newLng - lastFetchCenter.lng) > FETCH_THRESHOLD
      ) {
        // Update last fetched center
        setLastFetchCenter({ lat: newLat, lng: newLng });
        setLatitude(newLat);
        setLongitude(newLng);
        
        // Fetch new businesses
        fetchNearbyBusinesses({ lat: newLat, lng: newLng });
      }
    }
  };

  // const fetchNearbyBusinesses = (location) => {
  //   if (location && mapRef.current) {
  //     const service = new window.google.maps.places.PlacesService(mapRef.current);
  //     service.nearbySearch(
  //       {
  //         location,
  //         radius: 1000,
  //         type: [professionalType.googleTypeName], // Adjust the type as needed
  //       },
  //       (results, status) => {
  //         debugger;
  //         if (status === window.google.maps.places.PlacesServiceStatus.OK) {
  //           setBusinesses(results);
  //           // Fetch detailed information for each business
  //           results.forEach((business) => {
  //             service.getDetails(
  //               { placeId: business.place_id },
  //               (placeResult, placeStatus) => {
  //                 if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
  //                   debugger;
  //                   setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
  //                 }
  //               }
  //             );
  //           });
  //         } else {
  //           setBusinesses([]);
  //         }
  //       }
  //     );
  //   }
  // };
  // const mergeRegisteredAndMapBusinesses = () => {
  //   // Filter out registered businesses from the detailed list
  //   const filteredDetailedBusinesses = detailedBusinesses.filter((business) => {
  //     return !registeredProfessionals.some(
  //       (professional) => professional.reference === business.plus_code?.global_code ||
  //         professional.place_id === business.place_id
  //     );
  //   });
  
  //   // Combine registered professionals with the remaining detailed businesses
  //   const finalBusinesses = [
  //     ...registeredProfessionals.map((professional) => ({
  //       ...professional,
  //       isRegistered: true, // Mark as registered
  //       professionalData: professional
  //     })),
  //     ...filteredDetailedBusinesses.map((business) => ({
  //       ...business,
  //       isRegistered: false, // Mark as not registered
  //       professionalData: null
  //     }))
  //   ];
  //   setMergedBusinesses(finalBusinesses);
  // };

  const mergedBusinesses = [
    ...registeredProfessionals.map((professional) => ({
      ...professional,
      isRegistered: true,  // Mark as registered
      professionalData: professional || null
    })),...detailedBusinesses.filter((business) => 
                                !registeredProfessionals.some((professional) => professional.reference === business.plus_code?.global_code 
                                                            || professional.place_id === business.place_id))]
          .map((business) => {
                  
                  if(business.place_id==null && business.plus_code?.global_code == null)
                   {
                     var businessobject = searchBusinessByAddress(business.address);

                    if(businessobject != null)
                    {
                      return {
                        place_id: businessobject.place_id,
                        formatted_address: businessobject.formatted_address,
                        phone_number: businessobject.formatted_phone_number || businessobject.international_phone_number,
                        rating: businessobject.rating,
                        total_ratings: businessobject.user_ratings_total,
                        business_status: businessobject.business_status,
                        website: businessobject.website,
                        icon: businessobject.icon,
                        address_components: businessobject.address_components,
                        geometry: businessobject.geometry,
                        vicinity: businessobject.vicinity,
                        types: businessobject.types,
                        isRegistered: true, // true if there's a match, false otherwise
                        professionalData: business || null,
                      };
                    }
                    else
                    {
                      return {
                        ...business,
                        isRegistered: true, // true if there's a match, false otherwise
                        professionalData: business || null,
                      };
                    }

                  }

                    // If we're iterating over registeredProfessionals directly, we adjust the property to match
                    const businessId = business.place_id || business.plus_code?.global_code;
                    
                    // Find a matching professional in `registeredProfessionals` by `reference`
                    const matchedProfessional = registeredProfessionals.find(
                      (professional) => professional.reference === business.plus_code?.global_code
                    );

                  if(Boolean(matchedProfessional))
                  {
                    return {
                      ...matchedProfessional,
                      place_id: business.place_id,
                      formatted_address: business.formatted_address,
                      phone_number: business.formatted_phone_number || business.international_phone_number,
                      rating: business.rating,
                      total_ratings: business.user_ratings_total,
                      business_status: business.business_status,
                      website: business.website,
                      icon: business.icon,
                      address_components: business.address_components,
                      geometry: business.geometry,
                      vicinity: business.vicinity,
                      types: business.types,
                      isRegistered: true, // true if there's a match, false otherwise
                      professionalData: matchedProfessional || null,
                    };
                  }
                  else
                  {
                    return {
                    name: business.name,
                    place_id: business.place_id,
                    formatted_address: business.formatted_address,
                    phone_number: business.formatted_phone_number || business.international_phone_number,
                    rating: business.rating,
                    total_ratings: business.user_ratings_total,
                    business_status: business.business_status,
                    website: business.website,
                    icon: business.icon,
                    address_components: business.address_components,
                    geometry: business.geometry,
                    vicinity: business.vicinity,
                    types: business.types,
                    distanceFromCurrent: business.distanceFromCurrent,
                    // location: business.location,
                    isRegistered: Boolean(matchedProfessional),
                    professionalData: matchedProfessional || null,
                  
                      // ...business,
                      // isRegistered: Boolean(matchedProfessional), // true if there's a match, false otherwise
                      // professionalData: matchedProfessional || null,
                    };
                  }

                  });  


  const handlePlaceSelect = () => {
    if (autocomplete) {
      const place = autocomplete.getPlaces()[0];
      if (place) {
        setSearchAddress(place.formatted_address);
        setLatitude(place.geometry.location.lat());
        setLongitude(place.geometry.location.lng());
        fetchNearbyBusinesses();//place.geometry.location
      } else {
        setBusinesses([]);
      }
    }
  };

  const onLoad = (ref) => {
    setAutocomplete(ref);
  };

  const mapOnLoad = (map) => {
    mapRef.current = map;
    fetchNearbyBusinesses({ lat: latitude, lng: longitude });
  };

  const mapOptions = {
    zoomControl: true,
    mapTypeControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    draggableCursor: "pointer",
  };

  const handleMarkerClick = (marker) => {
    debugger;
    setSelectedMarker(marker);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const handleListItemClick = (professional) => {
    debugger;
    setFocusedMarker(professional); // Set the selected professional from the list
    //mapRef.current.panTo(professional.isRegistered?new window.google.maps.LatLng(professional.coordinate.lat, professional.coordinate.lng):new window.google.maps.LatLng(professional.geometry.location.lat(), professional.geometry.location.lng())); // Pan map to marker
    // Optional: Adjust the zoom level to make the marker more focused
    //mapRef.current.setZoom(16);
  };
  

  return (
    <Grid container spacing={2} className="choose-professionals-wrapper" style={{ height: '100vh' }}>
      <Grid item md={4} className="professional-list-wrapper" style={{ overflowY: 'auto', maxHeight: '100%', height: '100%' }}>
      {mergedBusinesses && mergedBusinesses.length > 0 ? (
          <ul className="professional-list">
            {mergedBusinesses.map((professional, index) => (
              <li key={index} className="professional-item" onClick={() => handleListItemClick(professional)}>
                <div className="professional-details">
                  <h4 className="professional-name">{professional.name}</h4>
                  <h5 className="professional-address">{professional.address}</h5>
                  <h5 className="professional-distancefromcurrent">{professional?.distanceFromCurrent?.crowflies}</h5>
                  <h5 className="professional-address">{professional.vicinity}</h5>
                  {professional.rating && (<Rate
                                              value={professional.rating}
                                              disabled
                                              allowHalf
                                            />)}
                      {professional.reviews && professional.reviews.length > 0 && (
                        <ul className="professional-reviews">
                          {professional.reviews.map((review, reviewIndex) => (
                            <li key={reviewIndex} className="professional-review">
                              <p>{review.text}</p>
                              <p>Rating: {review.rating} / 5</p>
                            </li>
                          ))}
                        </ul>
                      )}
                  </div>

                {professional.isRegistered ? (
                    <Button
                        variant="contained"
                        color="primary"
                        className="view-schedule-button"
                        onClick={() => handleViewScheduleClick(professional.professionalData)}
                      >
                        {t("AppointmentForm.ViewDisponibilities")}
                    </Button>
                ):(                
                    <Button
                      variant="contained"
                      color="primary"
                      className="not-available-button"
                      onClick={() => handleViewScheduleClick(null)}
                    >
                      {t("AppointmentForm.NotAvailable")}
                    </Button>
                )}

              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="h6" style={{ color: 'white' }}>{t("No professionals found in your area.")}</Typography>
        )}
      
        {/* {registeredProfessionals && registeredProfessionals.length > 0 ? (
          <ul className="professional-list">
            {registeredProfessionals.map((professional, index) => (
              <li key={professional.id} className="professional-item">
                <div className="professional-details">
                  <h4 className="professional-name">{index + 1} - {professional.name}</h4>
                  <h5 className="professional-address">{professional.address}</h5>
                  <h5 className="professional-distancefromcurrent">{professional?.distanceFromCurrent?.crowflies}</h5>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="view-schedule-button"
                  onClick={() => handleViewScheduleClick(professional)}
                >
                  {t("AppointmentForm.ViewDisponibilities")}
                </Button>
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="h6" style={{ color: 'white' }}>{t("No professionals found in your area.")}</Typography>
        )}
        {detailedBusinesses && detailedBusinesses.length > 0 && (
          <ul className="professional-list">
            {detailedBusinesses.map((business, index) => (
              <li key={business.place_id} className="professional-item">
                <div className="professional-details">
                  <h4 className="professional-name">{index + 1 + registeredProfessionals.length} - {business.name}</h4>
                  <h5 className="professional-address">{business.vicinity}</h5>
                  <Rate
                    value={business.rating}
                    disabled
                    allowHalf
                  />
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  className="not-available-button"
                  onClick={() => handleViewScheduleClick(null)}
                >
                  {t("AppointmentForm.NotAvailable")}
                </Button>
              </li>
            ))}
          </ul>
        )} */}
      </Grid>
      {/* googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As" */}
      <Grid item md={8} className="professionals-map-wrapper" style={{ height: '100%' }}>
         {/* <LoadScript  onLoad={mapOnLoad}  libraries={libraries}> */}
          <div className="search-box-container">
            <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceSelect}>
              <div>
                <PlaceRounded fontSize="small" htmlColor="#01b075" className="search-location-icon" />
                <input type="text" placeholder="Enter address" className="search-input" />
              </div>
            </StandaloneSearchBox>
          </div>
          <GoogleMap 
            mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "12px" }}
            center={{ lat: latitude, lng: longitude }}
            zoom={16}
            options={mapOptions}
            onLoad={mapOnLoad}
            onBoundsChanged={handleBoundsChanged} // Trigger search on map bounds change
          >
                {/* <Marker position={{ lat: latitude, lng: longitude }} 
                icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} /> */}
                  {mergedBusinesses && mergedBusinesses.length > 0 && mergedBusinesses.map((professional, index) => (
                      professional.isRegistered ? (
                              <Marker
                              key={professional.id}
                              position={{ lat: professional.coordinate.lat, lng: professional.coordinate.lng }}
                              icon={focusedMarker && focusedMarker.id === professional.id ? 
                                "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"} // Highlight the selected marker in red
                              
                              onClick={() => handleMarkerClick(professional)}
                            />
                            // label={(index + 1).toString()}
                      ):(              
                              <Marker
                              key={professional.id}
                              position={{ lat: professional.geometry.location.lat(), lng: professional.geometry.location.lng() }}
                              icon={focusedMarker && focusedMarker.id === professional.id ? 
                                "http://maps.google.com/mapfiles/ms/icons/red-dot.png" : 
                                "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"}
                              
                              onClick={() => handleMarkerClick(professional)}
                            />
                            // label={(index + 1).toString()}
                      )
                  ))}
                  {/* {detailedBusinesses.map((business, index) => (
                    <Marker
                      key={business.place_id}
                      position={{ lat: business.geometry.location.lat(), lng: business.geometry.location.lng() }}
                      icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
                      label={(index + 1 + registeredProfessionals.length).toString()}
                      onClick={() => handleMarkerClick(business)}
                    />
                  ))} */}
                  {selectedMarker && (
                    <InfoWindow
                      position={{
                        lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.coordinate.lat,
                        lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.coordinate.lng,
                      }}
                      onCloseClick={handleInfoWindowClose}
                    >
                      {selectedMarker.isRegistered  ? (
                              <div className="info-window">
                                    <h4>{selectedMarker.name}</h4>
                                    <p>{selectedMarker.vicinity || selectedMarker.address}</p>
                                    <button onClick={() => handleViewScheduleClick(selectedMarker)}>
                                      {t("AppointmentForm.ViewDisponibilities")}
                                    </button>
                            </div>
                      ):(                
                        <div className="info-window">
                                <h4>{selectedMarker.name}</h4>
                                <p>{selectedMarker.vicinity || selectedMarker.address}</p>
                                <button>
                                    {t("AppointmentForm.NotAvailable")}
                                </button>
                        </div>
                      )}
                    </InfoWindow>
                  )}
          </GoogleMap> 
        {/* </LoadScript> */}
      </Grid>
    </Grid>
  );
};

export default MapPicker;



// import React, { useState, useRef, useEffect } from "react";
// import { Grid, Button, Typography } from "@mui/material";
// import {
//   LoadScript,
//   StandaloneSearchBox,
//   GoogleMap,
//   Marker,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded } from "@mui/icons-material";
// import { useTranslation } from 'react-i18next';
// import { companyService } from "../app/services"; // Ensure this service is correctly set up

// const MapPicker = ({
//   Lat,
//   Lng,
//   Address,
//   selectedProfessionalType,
//   handleViewScheduleClick
// }) => {
//   const [searchAddress, setSearchAddress] = useState("");
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);
//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);
//   const [professionalType, setProfessionalType] = useState(selectedProfessionalType);
//   const { t } = useTranslation();

//   const [registeredProfessionals, setRegisteredProfessionals] = useState([]);
//   const [detailedBusinesses, setDetailedBusinesses] = useState([]);
//   const mapRef = useRef(null);

//   useEffect(() => {
//     // Fetch professionals from your backend
//     companyService
//       .getCompaniesByCategoryAndLocation(professionalType.name, { Lat: latitude, Lng: longitude })
//       .then((company) => {
//         setRegisteredProfessionals(company);
//       })
//       .catch((error) => {
//         console.error("Failed to fetch professionals:", error);
//       });



//   }, [professionalType, latitude, longitude]);

//   const handlePlaceSelect = () => {

//     if (autocomplete) {
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());

//         const service = new window.google.maps.places.PlacesService(mapRef.current);
//         service.nearbySearch(
//           {
//             location: place.geometry.location,
//             radius: 1000,
//             type: [professionalType.googleTypeName], // Adjust the type as needed"professional_services"
//           },
//           (results, status) => {
//             if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//               setBusinesses(results);
//               // Fetch detailed information for each business
//               results.forEach((business) => {
//                 service.getDetails(
//                   { placeId: business.place_id },
//                   (placeResult, placeStatus) => {
//                     if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
//                       setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
//                     }
//                   }
//                 );
//               });
//             } else {
//               setBusinesses([]);
//             }
//           }
//         );
//       } else {
//         setBusinesses([]);
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);

// debugger;
//           const service = new window.google.maps.places.PlacesService(mapRef.current);
//       service.nearbySearch(
//         {
//           location: address,
//           radius: 1000,
//           type: [professionalType.googleTypeName], // Adjust the type as needed
//         },
//         (results, status) => {
//           debugger;
//           if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//             setBusinesses(results);
//             // Fetch detailed information for each business
//             results.forEach((business) => {
//               service.getDetails(
//                 { placeId: business.place_id },
//                 (placeResult, placeStatus) => {
//                   if (placeStatus === window.google.maps.places.PlacesServiceStatus.OK) {
//                     setDetailedBusinesses((prevDetails) => [...prevDetails, placeResult]);
//                   }
//                 }
//               );
//             });
//           } else {
//             setBusinesses([]);
//           }
//         }
//       );
//   };

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };

//   return (
//     <Grid container spacing={2} className="choose-professionals-wrapper" style={{ height: '600px' }}>
//       <Grid item md={4} className="professional-list-wrapper" style={{ backgroundColor: 'Blue' }}>
//         {registeredProfessionals && registeredProfessionals.length > 0 ? (
//           <ul className="professional-list">
//             {registeredProfessionals.map((professional, index) => (
//               <li key={professional.id} className="professional-item">
//                 <h4 className="professional-name">{index + 1} - {professional.name}</h4>
//                 <h5 className="professional-address">{professional.address}</h5>
//                 <h5 className="professional-distancefromcurrent">{professional?.distanceFromCurrent?.crowflies}</h5>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   className="view-schedule-button"
//                   onClick={() => handleViewScheduleClick(professional)}
//                 >
//                   {t("AppointmentForm.ViewDisponibilities")}
//                 </Button>
//               </li>
//             ))}
//           </ul>
//         ) : (
//           <Typography variant="h6" style={{ color: 'white' }}>{t("No professionals found in your area.")}</Typography>
//         )}
//         {detailedBusinesses && detailedBusinesses.length > 0 && (
//           <ul className="professional-list">
//             {detailedBusinesses.map((business, index) => (
//               <li key={business.place_id} className="professional-item">
//                 <h4 className="professional-name">{index + 1 + registeredProfessionals.length} - {business.name}</h4>
//                 <h5 className="professional-address">{business.vicinity}</h5>
//                 <h5 className="professional-rating">Rating: {business.rating} / 5</h5>
//                 {business.reviews && business.reviews.length > 0 && (
//                   <ul className="professional-reviews">
//                     {business.reviews.map((review, reviewIndex) => (
//                       <li key={reviewIndex} className="professional-review">
//                         <p>{review.text}</p>
//                         <p>Rating: {review.rating} / 5</p>
//                       </li>
//                     ))}
//                   </ul>
//                 )}
//               </li>
//             ))}
//           </ul>
//         )}
//       </Grid>
//       <Grid item md={8} className="professionals-map-wrapper" style={{ backgroundColor: "Red" }}>
//         <LoadScript googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As" libraries={["places"]}>
//           <div className="search-box-container">
//             <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={handlePlaceSelect}>
//               <div>
//                 <PlaceRounded fontSize="small" htmlColor="#01b075" className="search-location-icon" />
//                 <input type="text" placeholder="Enter address" className="search-input" />
//               </div>
//             </StandaloneSearchBox>
//           </div>
//           <GoogleMap
//             mapContainerStyle={{ height: "100%", width: "100%", borderRadius: "12px" }}
//             center={{ lat: latitude, lng: longitude }}
//             zoom={14}
//             options={mapOptions}
//             onLoad={(map) => { mapRef.current = map; }}
//           >
//             <Marker position={{ lat: latitude, lng: longitude }} icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"} />
//             {registeredProfessionals && registeredProfessionals.length > 0 && registeredProfessionals.map((professional, index) => (
//               <Marker
//                 key={professional.id}
//                 position={{ lat: professional.coordinate.lat, lng: professional.coordinate.lng }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
//                 label={(index + 1).toString()}
//                 onClick={() => handleMarkerClick(professional)}
//               />
//             ))}
//             {detailedBusinesses.map((business, index) => (
//               <Marker
//                 key={business.place_id}
//                 position={{ lat: business.geometry.location.lat(), lng: business.geometry.location.lng() }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
//                 label={(index + 1 + registeredProfessionals.length).toString()}
//                 onClick={() => handleMarkerClick(business)}
//               />
//             ))}
//             {selectedMarker && (
//               <InfoWindow
//                 position={{
//                   lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.location.lat,
//                   lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.location.lng,
//                 }}
//                 onCloseClick={handleInfoWindowClose}
//               >
//                 <div className="info-window">
//                   <h4>{selectedMarker.name}</h4>
//                   <p>{selectedMarker.vicinity || selectedMarker.address}</p>
//                 </div>
//               </InfoWindow>
//             )}
//           </GoogleMap>
//         </LoadScript>
//       </Grid>
//     </Grid>
//   );
// };

// export default MapPicker;




// import React, { useState, useEffect, useRef } from "react";
// import {
//   Accordion,
//   AccordionSummary,
//   AccordionDetails,
//   Stack,
//   TextField,
//   Button,
//   Select,
//   MenuItem,
//   Grid,
//   Box,
// } from "@mui/material";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   StandaloneSearchBox,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded  } from "@mui/icons-material";

// const MapPicker = ({ Lat, Lng, Address, registeredProfessionals , handleViewScheduleClick}) => {
//   const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
//   const [searchAddress, setSearchAddress] = useState("");
//   const [error, setError] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [libraries] = useState(["places"]);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);

//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);

//   const [registeredProfessionalsl, setRegisteredProfessionalsl] = useState(registeredProfessionals);

//   const mapRef = useRef(null);

//   const handlePlaceSelect = () => {
//     if (autocomplete) {
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());
//         setError(null);

//         const service = new window.google.maps.places.PlacesService(mapRef.current);
//         service.nearbySearch(
//           {
//             location: place.geometry.location,
//             radius: 5000,
//             type: ["professional_services"], // You can adjust the type to your needs
//           },
//           (results, status) => {
//             if (status === window.google.maps.places.PlacesServiceStatus.OK) {
//               setBusinesses(results);
//             } else {
//               setBusinesses([]);
//               setError("Error retrieving business details");
//             }
//           }
//         );
//       } else {
//         setBusinesses([]);
//         setError("Error retrieving place details");
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);
//   };

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };

//   return (
//     <Grid container spacing={2} className="choose-professionals-wrapper" style={{backgroundColor:'Green',height:'100%'}}>
//     {registeredProfessionalsl && registeredProfessionalsl.length > 0 && (
//       <Grid item md={4} className="professional-list-wrapper">
//         <ul className="professional-list">
//           {registeredProfessionalsl.map((professional) => (
//             <li key={professional.id} className="professional-item">
//               <h4 className="professional-name">
//                 {professionals.indexOf(professional)} -{" "}
//                 {professional.name}
//               </h4>
//               <h5 className="professional-address">
//                 {professional.address}
//               </h5>
//               <h5 className="professional-distancefromcurrent">
//                 {professional?.distanceFromCurrent?.crowflies}
//               </h5>
//               <button
//                 className="view-schedule-button"
//                 onClick={() => handleViewScheduleClick(professional)}
//               >
//                 {t("AppointmentForm.ViewDisponibilities")}
//               </button>
//             </li>
//           ))}
//         </ul>
//       </Grid>
//     )}
//     <Grid item md={8} className="professionals-map-wrapper" style={{backgroundColor:'Blue'}}> 
//       <LoadScript
//                  googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As"
//         libraries={libraries}
//       >
//         <div className="search-box-container" style={{backgroundColor:'red'}}>
//           <StandaloneSearchBox
//             onLoad={onLoad}
//             onPlacesChanged={handlePlaceSelect}
//           >
//             <div>
//               <PlaceRounded
//                 fontSize="small"
//                 htmlColor="#01b075"
//                 className="search-location-icon"
//               />
//               <input
//                 type="text"
//                 placeholder="Enter address"
//                 className="search-input"
//               />
//             </div>
//           </StandaloneSearchBox>
//         </div>
//         <GoogleMap
//           mapContainerStyle={{
//             height: "100%",
//             width: "100%",
//             borderRadius: "12px",
//           }}
//           center={{ lat: latitude, lng: longitude }}
//           zoom={14}
//           options={mapOptions}
//           onLoad={(map) => {
//             mapRef.current = map;
//             setIsGoogleApiLoaded(true);
//           }}
//         >
//           <Marker
//             position={{ lat: latitude, lng: longitude }}
//             icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
//           />

//           {registeredProfessionals &&
//             registeredProfessionals.map((professional, index) => (
//               <Marker
//                 key={professional.id}
//                 position={{
//                   lat: professional.location.lat,
//                   lng: professional.location.lng,
//                 }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
//                 label={(index + 1).toString()}
//                 onClick={() => handleMarkerClick(professional)}
//               />
//             ))}

//           {businesses &&
//             businesses.map((business, index) => (
//               <Marker
//                 key={business.place_id}
//                 position={{
//                   lat: business.geometry.location.lat(),
//                   lng: business.geometry.location.lng(),
//                 }}
//                 icon={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
//                 label={(index + 1 + registeredProfessionals.length).toString()}
//                 onClick={() => handleMarkerClick(business)}
//               />
//             ))}

//           {selectedMarker && (
//             <InfoWindow
//               position={{
//                 lat: selectedMarker.geometry ? selectedMarker.geometry.location.lat() : selectedMarker.location.lat,
//                 lng: selectedMarker.geometry ? selectedMarker.geometry.location.lng() : selectedMarker.location.lng,
//               }}
//               onCloseClick={handleInfoWindowClose}
//             >
//               <div className="info-window">
//                 <h4>{selectedMarker.name}</h4>
//                 <p>{selectedMarker.vicinity || selectedMarker.address}</p>
//               </div>
//             </InfoWindow>
//           )}
//         </GoogleMap>
//       </LoadScript>
//       </Grid>
//   </Grid>
//   );
// };

// export default MapPicker;

// import React, { useEffect, useState } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   Marker,
//   StandaloneSearchBox,
//   InfoWindow,
// } from "@react-google-maps/api";
// import { PlaceRounded } from "@mui/icons-material";

// const MapPicker = ({ Lat, Lng, Address, professionals }) => {
//   const [isGoogleApiLoaded, setIsGoogleApiLoaded] = useState(false);
//   const [searchAddress, setSearchAddress] = useState("");
//   const [error, setError] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [libraries] = useState(["places"]);
//   const [businesses, setBusinesses] = useState([]);
//   const [selectedMarker, setSelectedMarker] = useState(null);

//   const [latitude, setLatitude] = useState(Lat);
//   const [longitude, setLongitude] = useState(Lng);
//   const [address, setAddress] = useState(Address);

//   const handleSearchAddressFromIP = (ipAddress) => {
//     if (!ipAddress) {
//       setError("Please enter an address");
//       return;
//     }

//     debugger;
//     fetch(
//       `https://maps.googleapis.com/maps/api/geocode/json?ip=${encodeURIComponent(
//         ipAddress
//       )}&key=AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As`
//     )
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "OK") {
//           debugger;
//           const location = data.results[0].geometry.location;
//           setLatitude(location.lat);
//           setLongitude(location.lng);
//           setError(null);
//         } else {
//           setError("Error retrieving location");
//         }
//       })
//       .catch((error) => {
//         setError(`Error retrieving location: ${error.message}`);
//       });
//   };

//   const handlePlaceSelect = () => {
//     debugger;
//     if (autocomplete) {
//       //&& map
//       const place = autocomplete.getPlaces()[0];
//       if (place) {
//         // const place = places[0];
//         setSearchAddress(place.formatted_address);
//         setLatitude(place.geometry.location.lat());
//         setLongitude(place.geometry.location.lng());
//         setError(null);
//       } else {
//         setBusinesses([]);
//         setError("Error retrieving place details");
//       }
//     }
//   };

//   const onLoad = (ref) => {
//     setAutocomplete(ref);
//   };



//   if (error) {
//     return <div>{error}</div>;
//   }

//   const mapOptions = {
//     zoomControl: true,
//     mapTypeControl: false,
//     streetViewControl: false,
//     fullscreenControl: false,
//     draggableCursor: "pointer",
//   };

//   const handleMarkerClick = (marker) => {
//     setSelectedMarker(marker);
//   };

//   const handleInfoWindowClose = () => {
//     setSelectedMarker(null);
//   };
//   return (
//     <>
//       <LoadScript
//         googleMapsApiKey="AIzaSyB_j8NP_ZfkMFGHNxqsCL_pPhEIBlBp9As"
//         libraries={libraries}
//       >
//         <div className="search-box-container">
//           <StandaloneSearchBox
//             onLoad={onLoad}
//             onPlacesChanged={handlePlaceSelect}
//           >
//             <div>
//               <PlaceRounded
//                 fontSize="small"
//                 htmlColor="#01b075"
//                 className="search-location-icon"
//               />
//               <input
//                 type="text"
//                 placeholder="Enter address"
//                 className="search-input"
//               />
//             </div>
//           </StandaloneSearchBox>
//         </div>
//         <GoogleMap
//           mapContainerStyle={{
//             height: "100%",
//             width: "100%",
//             borderRadius: "12px",
//           }}
//           center={{ lat: latitude, lng: longitude }}
//           zoom={14}
//           options={mapOptions}
//         >
//           <Marker
//             position={{ lat: latitude, lng: longitude }}
//             icon={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
//           />

//           {professionals &&
//             professionals.map((business) => (
//               <div key={business.id}>
//                 <Marker
//                   position={{
//                     lat: business.coordinate.lat,
//                     lng: business.coordinate.lng,
//                   }}
//                   label={professionals.indexOf(business).toString()}
//                   onClick={() => handleMarkerClick(business)}
//                 />
//               </div>
//             ))}

//           {selectedMarker && (
//             <InfoWindow
//               position={{
//                 lat: selectedMarker.coordinate.lat,
//                 lng: selectedMarker.coordinate.lng,
//               }}
//               onCloseClick={handleInfoWindowClose}
//             >
//               <div className="info-window">
//                 <h4>{selectedMarker.name}</h4>
//                 <p>{selectedMarker.address}</p>
//               </div>
//             </InfoWindow>
//           )}
//         </GoogleMap>
//       </LoadScript>
//     </>
//   );
// };

// export default MapPicker;

