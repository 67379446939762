import { BehaviorSubject } from 'rxjs';
import ApiCore from './common/api-core';
import { handleResponse } from '../helpers';

const url = "CompanyApi";

const apiTasks = new ApiCore({
  getAll: true,
  getSingle: true,
  post: true,
  put: true,
  patch: true,
  delete: true,
  url: url
});

export const companyService = {
  companylogin,
  getAllCategories,
  getCompaniesByCategoryAndLocationAndCompanyid,
  getCompaniesByCategoryAndLocationAndCompanyidNotConnected,
  getCompanyDisponibilities,
  getCompanyDisponibilitiesNotConnected,
  getCompanyServicesByEmployee,
  getCompanyServicesByEmployeeNotConnected,
  saveWorkingHours,
  getCompanyEmployees,
  getCompanyEmployeesNotConnected,
  requestCompany,
  addCompany,
  modifyCompany,
  deleteCompany,
  addEmployeetoCompany,
  modifyEmployeetoCompany,
  deleteEmployeetoCompany
};


function companylogin(usernameText, emailText, passwordText, returnurlText) {
  return apiTasks.post('CompanyLogin', {
    username: usernameText,
    email: emailText,
    password: passwordText,
    returnurl: returnurlText,
    grant_type: 'password',
  })
    .then(function (response) {
      if (!response.isAxiosError) {
        localStorage.setItem('token', response.token);
        //currentUserSubject.next(response.token);
        return response.user;
      } else {
          
        throw new Error(response.message); // Throw an error to be caught in the catch block
      }
    })
    .catch(function (error) {
      
      throw new Error('Login failed: ' + error.message); // Throw a custom error message
    });
}

function getAllCategories() {
  try {
    return apiTasks.getAll('GetAllCompanyCategory')
      .then(function (response) {
        if (!response.isAxiosError) {
          return response;
        } else {
          throw new Error('API request error');
        }
      });
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
}

function getCompaniesByCategoryAndLocationAndCompanyid(category, location,companyid) {

  const params = {
    category: category,
    location: location,
    companyid:companyid
  };

  return apiTasks.post('getCompaniesByParameters',params)// { 'category': category, 'location': location })
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}
function getCompaniesByCategoryAndLocationAndCompanyidNotConnected(category, location,companyid) {

  const params = {
    category: category,
    location: location,
    companyid:companyid
  };

  return apiTasks.post('GetCompaniesByParametersNotConnected',params)// { 'category': category, 'location': location })
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}


function saveWorkingHours(companyUsername,workinghours) {

  return apiTasks.post('SaveWorkingHours',{companyUsername, workinghours })
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}


function requestCompany(companyData) {
  return apiTasks.post('RequestCompany', companyData)
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function addCompany(companyData) {
  return apiTasks.post('CreateCompany', companyData)
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function getCompanyDisponibilities(company,selectedEmployee,ReferenceDate,numberOfDays,appointmentDuration) {
  return apiTasks.post('GetCompanyDisponibilities', {company,employee:selectedEmployee,ReferenceDate,numberOfDays,appointmentDuration})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function getCompanyDisponibilitiesNotConnected(company,selectedEmployee,ReferenceDate,numberOfDays,appointmentDuration) {
  return apiTasks.post('GetCompanyDisponibilitiesNotConnected', {company,employee:selectedEmployee,ReferenceDate,numberOfDays,appointmentDuration})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}



function getCompanyEmployees(companyUsername) {
  return apiTasks.post('GetCompanyEmployees', {Name:companyUsername})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function getCompanyEmployeesNotConnected(companyUsername) {
  return apiTasks.post('GetCompanyEmployeesNotConnected', {Name:companyUsername})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function getCompanyServicesByEmployee(companyid, employee) {
  return apiTasks.post('GetCompanyServicesbyEmployee', {companyId:companyid,employeeUsername:employee})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function getCompanyServicesByEmployeeNotConnected(companyid,employee) {
  return apiTasks.post('GetCompanyServicesbyEmployeeNotConnected', {companyId:companyid,employeeUsername:employee})
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function modifyCompany(companyId, companyData) {
  return apiTasks.put(`modifyCompany/${companyId}`, companyData)
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function deleteCompany(companyId) {
  return apiTasks.delete(`deleteCompany/${companyId}`)
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function addEmployeetoCompany(companyUsername,employeeUsename,startDate,endDate,meetingTime,breakTimes) {
  return apiTasks.post('AddEmployeeToCompany',  {companyUsername,Username:employeeUsename,startDate,endDate,meetingTime,breakTimes} )
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}


function modifyEmployeetoCompany(companyUsername,employeeUsename,planningId,startDate,endDate,meetingTime,breakTimes) {
  return apiTasks.post('EditEmployeeToCompany',  {companyUsername,Username:employeeUsename,PlanningId:planningId,startDate,endDate,meetingTime,breakTimes} )
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

function deleteEmployeetoCompany(planningId) {
  return apiTasks.post('DeleteEmployeeToCompany',  {PlanningId:planningId} )
    .then(function (response) {
      if (!response.isAxiosError) {
        return response;
      }
    });
}

