// TimeSlotList.js
import React, { useEffect, useState } from 'react';
import {Button,Grid } from '@mui/material';
import { companyService } from "../../../app/services";

const TimeSlotList = ({ professional,employee,selectedDate, interval = 30,timeSlots, onSlotSelect }) => {



  const handleSlotClick = (slot) => {
    onSlotSelect(slot);
  };

  return (
    <div style={{ width: '100%', height: '300px', overflowY: 'auto' }}>
    <Grid container spacing={2}>
      {timeSlots && timeSlots.map((slot) => (
        <Grid item xs={12} sm={6} md={4} key={slot.id}>
          <Button
            onClick={() => handleSlotClick(slot)}
            style={{
              width: '100%',
              textAlign: 'center',
            }}
            variant="outlined"
          >
            {new Date(slot.startDate).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Button>
        </Grid>
      ))}
    </Grid>
  </div>
    // <div style={{ width: '100%', height: '300px', overflowY: 'auto' }}>
    //   <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
    //     {timeSlots && timeSlots.map((slot) => (
    //       <li key={slot.id} style={{ marginBottom: '5px' }}>
    //         <Button
    //           onClick={() => handleSlotClick(slot)}
    //           style={{
    //             width: '100%',
    //             textAlign: 'center',
    //           }}
    //         >
    //           {new Date(slot.startDate).toLocaleTimeString([], {
    //             hour: '2-digit',
    //             minute: '2-digit',
    //           })}
    //         </Button>
    //       </li>
    //     ))}
    //   </ul>
    // </div>
  );
};

export default TimeSlotList;
