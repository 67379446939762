import React,{useState,useEffect} from 'react';
 import Logo from '../../assets/images/logo_transparent.png';
 import { useNavigate, Link } from 'react-router-dom';
import './footer.css';
import Image from "react-bootstrap/Image";
import { useTranslation } from 'react-i18next';
import {Select,MenuItem } from '@mui/material';


const Footer = () => {
  const navigate = useNavigate();
  const { t,i18n } = useTranslation();
	const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
      setSelectedLanguage(lng);    
      localStorage.setItem('selectedLanguage', lng);
    };



    const handleRegisterClick = () => {
      navigate('/register');
    };
  

    useEffect(() => {
      setSelectedLanguage(i18n.language);
    }, [i18n.language]);


return (
<footer className='dtree_footer'>
    <div className="dtree_footer section__padding">
      <div className="dtree_footer-links">
        <div className="dtree_footer-links_logo">
        <Link to="/" style={{ width: "5em" }}>
        <Image src={Logo} alt="Logo" />
      </Link>
          <p>{t('HomePage.dashboardHeaderPresentation')}</p>
        </div>
        <div className="dtree_footer-links_div">
          <h4>{t('HomePage.Footer_site')}</h4>
          <p>{t('HomePage.Footer_prices')}</p>
          <p>{t('HomePage.Footer_contact')}</p>
          <Link to="/login"><p>{t('HomePage.Common_login')}</p></Link>
          <Link to="/register"><p>{t('HomePage.Common_register')}</p></Link>
        </div>
        <div className="dtree_footer-links_div">
          <h4>{t('HomePage.Footer_support')}</h4>
          <p>{t('HomePage.Footer_cgv')}</p>
          <p>{t('HomePage.Footer_cgu')}</p>
          <p>{t('HomePage.Footer_cookies')}</p>
        </div>      
        <div className="dtree_footer-links_div">
          <h4>{t('HomePage.Common_language')}</h4>
             <Select
                labelId="language-select" className='language-select'
                id="language-select"
                label={t('HomePage.Common_language')}
                value={selectedLanguage}
                onChange={(e) => changeLanguage(e.target.value)}
              >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">Français</MenuItem>
                <MenuItem value="de">German</MenuItem>
                <MenuItem value="ar">Arabic</MenuItem>
              </Select>
        </div>
      </div> 

      <hr className="footer-hr" />
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} diarytree.com. {t('HomePage.Footer_rightReserved')}.</p>
        </div>
    </div>
  </footer>
);
}

export default Footer;