import React, { useState,useEffect } from "react";
import { useParams, useHistory,useNavigate,useLocation } from 'react-router-dom';
import {
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  FormControlLabel,
  Switch,
  InputAdornment,  
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Image from "react-bootstrap/Image";
import { authenticationService, companyService } from "../../app/services";
import "../../assets/styles/sharedstyle.css";
import image from "../../assets/images/logo_transparent.png";
import { AccountCircle, PersonOutlineOutlined } from "@mui/icons-material";
import useWindowResizeListener from "../../helpers/useWindowResizeListener";
import { useTranslation } from 'react-i18next';

const CustomTextField = (props) => (
  <TextField variant="outlined" {...props} fullWidth required />
);

const LoginPage = () => {
  const { height } = useWindowResizeListener();
  const [errorMessage, setErrorMessage] = useState("");
  const [showResetPopup, setShowResetPopup] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const history = useHistory();|| '/schedule'
  const location = useLocation();
  
  console.log("loading loginpage");
  
  console.log(location);
  
  const returnUrl = location.state?.from ;

  const openResetPopup = () => {
    setShowResetPopup(true);
  };

  const closeResetPopup = () => {
    setShowResetPopup(false);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let username = "";
      let email = "";

      if (re.test(values.username)) {
        email = values.username;
      } else {
        username = values.username;
      }

        // Access the value of isCompany using values.isCompany
        const isCompany = values.isCompany;
    if(isCompany)
    {
      await companyService
      .companylogin(username, email, values.password, "")
      .then(
        (user) => {
          console.log(returnUrl);
          navigate(returnUrl);
        },
        (error) => {

          setErrorMessage(error.message);
          // setStatus(error);
        }
      );
    }
    else
    {      
      await authenticationService
      .login(username, email, values.password, "")
      .then(
        (user) => {
                // Ensure returnUrl is valid, otherwise, redirect to a default route
          const destination = returnUrl?.pathname || '/';
          console.log(destination);
          // navigate(destination);
          navigate("/schedule");
          window.location.reload();
        },
        (error) => {

          setErrorMessage(error.message);
        }
      );
    }

      // Redirect to the desired page on successful login
      // For example, using the 'react-router-dom' library:
      // history.push('/dashboard');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleForgotPassword = async (values, event) => {

    try {
      await authenticationService
      .resetPassword(values.email) // assuming username holds the email value
      .then(
        (user) => {
          alert(t('LoginRegisterPage.Password_reset_email_sent'));

        },
        (error) => {
          setErrorMessage(error.message);
        }
      );
    } catch (error) {
      console.error('Error sending password reset email', error);
      alert(t('LoginRegisterPage.Error_sending_password_reset_email'));
    }
};

  const btnstyle = { margin: "8px 0" };

  return (
    <div className="login-page" style={{ height: height }}>
      <Formik
        initialValues={{
          username: "",
          password: "",
          remember: false,
          isCompany: false
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().required(t('LoginRegisterPage.Required')),
          password: Yup.string().required(t('LoginRegisterPage.Required')),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <div align="center" style={{ minHeight: height, width: "100%" }}>
            <Grid
              container
              spacing={12}
              style={{ height: "100%", Padding: "0px!important" }}
            >
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "5%",
                  background: "#f5f7fd",
                  height: "110vh",
                }}
              >
                <Grid style={{ paddingTop: "10px", paddingBottom: "50px" }}>
                  <Link
                    href="/"
                    style={{ width: "200px", height: "100px", offset: "999" }}
                  >
                    <img src={image} style={{ width: "10em" }} />
                  </Link>
                </Grid>
                <Grid>
                  {/* Google and Apple login buttons style={{ width: '50%' }}*/}
                </Grid>
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}

                <br />
                <Grid className="auth-wrapper">
                  <Typography variant="h5">{t('LoginRegisterPage.Welcome_back')}</Typography>
                  <Typography variant="h6">{t('LoginRegisterPage.Sign_in')}</Typography>
                  <Form spacing={4}>
                    <Field
                      align="center"
                      as={CustomTextField}
                      name="username"
                      placeholder={t('LoginRegisterPage.Enter_username_or_email')}
                      helperText={<ErrorMessage name="username" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlineOutlined />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Field
                      as={CustomTextField}
                      align="center"
                      name="password"
                      placeholder={t('LoginRegisterPage.Enter_password')}
                      type="password"
                      helperText={<ErrorMessage name="password" />}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />

                    <br />
                    {/*   <Field
                      as={FormControlLabel}
                      name='remember'
                      control={<Switch />}
                      label="Remember me"
                    /> */}

                <Link href="#" className="forgot-pass" onClick={openResetPopup}>
                {t('LoginRegisterPage.Forgot_password')}
                </Link>
      {/* Reset password popup */}
      <Dialog open={showResetPopup} onClose={closeResetPopup}>
      <DialogTitle>{t('LoginRegisterPage.Reset_Password')}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email(t('LoginRegisterPage.Invalid_email')).required(t('LoginRegisterPage.Required')),
            })}
            onSubmit={(values, { setSubmitting }) => {
              // Handle reset password logic here
              handleForgotPassword(values);
              setSubmitting(false);
              closeResetPopup();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <Field
                  as={TextField}
                  name="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  required
                />
                <br />
                <ErrorMessage name="email" />

                <DialogActions>
                <Button onClick={closeResetPopup}>{t('LoginRegisterPage.Cancel')}</Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                  >
                   {t('LoginRegisterPage.Reset_Password')}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
                  <div style={{ textAlign: 'left', marginTop: '10px' }}>
                    <Field
                            as={FormControlLabel}
                            control={<Switch name="isCompany" />}
                            label={t('LoginRegisterPage.I_am_a_company')}
                          />
                          </div>
                    <br />
                    <Button
                      type="submit"
                      className="submit-btn"
                      variant="contained"
                      disabled={isSubmitting}
                      style={btnstyle}
                      fullWidth
                    >
                      {isSubmitting ? t('LoginRegisterPage.Loading') : t('LoginRegisterPage.Sign_in')}
                    </Button>
                  </Form>
                  <Typography className="signup-link">
                  {t('LoginRegisterPage.Dont_have_account')} <Link href="/register">{t('LoginRegisterPage.Sign_up')}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Formik>
    </div>
  );
};

export { LoginPage };

// import React,{useState} from 'react'
// import { Grid, Paper, Avatar, TextField, Button, Typography, Link } from '@mui/material'
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import Image from 'react-bootstrap/Image';
// import Switch from '@mui/material/Switch';
// import image from '../../assets/images/logo_transparent.png'; // Tell webpack this JS file uses this image
// import imageGoogle from '../../assets/images/google_logo.png'; // Tell webpack this JS file uses this image
// import imageApple from '../../assets/images/mac_logo.png'; // Tell webpack this JS file uses this image
// import { GoogleLogin } from '@react-oauth/google';
// import AppleLogin from 'react-apple-login'

// import { authenticationService } from '../../app/services';
// import '../../assets/styles/sharedstyle.css';
// import { Navigate, useNavigate } from 'react-router-dom';
// // import image from '../../assets/images/calendarLogin.jpg'; // Tell webpack this JS file uses this image

// const responseMessage = (response) => {
//     console.log(response);
// };
// const errorMessage = (error) => {
//     console.log(error);
// };

// const LoginPage = () => {
//     const [isLoggedIn, setIsLoggedIn] = useState(authenticationService.currentUserToken != null);
//     const [errorMessage, setErrorMessage] = React.useState("");
//     const handleSubmit = async (values) => {
//         let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//         let username = "";
//         let email = "";
//         if ( re.test(values.username) ) {
//             email = values.username;
//         }
//         else
//         {
//             username = values.username;
//         }
//         authenticationService.login(username,email, values.password,"")
//             .then(
//                 (user) => {
//                     if(user != null)
//                     {
//                         window.location.reload();
//                         //setIsLoggedIn(true);
//                     }
//                 },
//                 (error) => {
//                     setErrorMessage(error.message); // Set the error message to be displayed on the login page

//                   //  setSubmitting(false);
//                     // setStatus(error);
//                 }
//             )
//             .catch((error) => {
//                 console.error('Login error:', error);
//                 setErrorMessage('Login failed: ' + error.message); // Set the error message to be displayed on the login page
//               });
//     };

//     const btnstyle = { margin: '8px 0' }
//     return (
//         <div>
//         <Formik
//           initialValues={{
//             username: '',
//             email: '',
//             password: '',
//             remember: false,
//           }}
//           onSubmit={handleSubmit}
//         >
//           {({ isSubmitting }) => (
//             <div align='center' style={{ height: '86vh', width: '100%' }}>
//               <Grid container spacing={12} style={{ height: '100%', width: '60%', Padding: '0px!important' }}>
//                 <Grid item xs={12} style={{ marginTop: '5%' }}>
//                   <Grid style={{ width: '50%', PaddingTop: '10px' }}>
//                     <Link to="/" style={{ margin: '30px 0px 30px 0' }}>
//                       <Image src={image} style={{ width: '15em' }} rounded></Image>
//                     </Link>
//                     <h5>Sign in to diarytree</h5>
//                   </Grid>
//                   <Grid>
//                     {/* Google and Apple login buttons */}
//                   </Grid>
//                   <hr />
//                   {errors.login && touched.login && (
//                       <div class="error-message">{errors.login}</div>
//                     )}
//                   <Grid style={{ width: '50%' }}>
//                     <Form spacing="10">
//                       <Field
//                         align='center'
//                         as={TextField}
//                         label='Username or Email'
//                         name="username"
//                         placeholder='Enter username or email ..'
//                         fullWidth
//                         required
//                         style={{ marginBottom: '10px' }}
//                         helperText={<ErrorMessage name="username" />}
//                       />
//                       <Field
//                         as={TextField}
//                         align='center'
//                         label='Password'
//                         name="password"
//                         placeholder='Enter password ..'
//                         type='password'
//                         fullWidth
//                         required
//                         style={{ marginBottom: '10px' }}
//                         helperText={<ErrorMessage name="password" />}
//                       />
//                       <Field
//                         as={FormControlLabel}
//                         name='remember'
//                         control={<Switch style={{ marginBottom: '10px' }} defaultChecked />}
//                         label="Remember me"
//                       />
//                       <Link href="#">Forgot password ?</Link>
//                       <Button
//                         type='submit'
//                         color='primary'
//                         variant="contained"
//                         disabled={isSubmitting}
//                         style={btnstyle}
//                         fullWidth
//                       >
//                         {isSubmitting ? "Loading.." : "Sign in"}
//                       </Button>
//                     </Form>
//                     <Typography>
//                       Don’t you have an account?
//                       <Link href="/register">Sign up</Link>
//                     </Typography>
//                   </Grid>
//                   )}
//                 </Grid>
//               </Grid>
//             </div>
//         )}
//         </Formik>
//       </div>
//     );
// };

// export { LoginPage };
