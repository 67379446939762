const RightSidebar = () => {
  return (
    <div className="schedule-right-sidebar">
      <div className="right-sidebar-panel activity-information">
        <h6 className="title-right-sidebar">Next appointments</h6>
        <div>
          <div className="colored-dot"></div>
          <p>Project Launching</p>
        </div>
        <div>
          <div className="colored-dot"></div>
          <p>Office Meeting</p>
        </div>
        <div>
          <div className="colored-dot"></div>
          <p>Weekly Report</p>
        </div>
        <div>
          <div className="colored-dot"></div>
          <p>Client's Meeting / Dealing</p>
        </div>
      </div>
      <div className="right-sidebar-panel recent-activity">
        <h6 className="title-right-sidebar">Recent Activity</h6>
        <div>
          <div className="image-recent-activity-item">img</div>
          <div>
            <p className="title-recent-activity-item">
              Added new Meeting by Greg
            </p>
            <p className="info-activity-item">
              Offline Meeting with Dan Bvrown at 2 8 June 2023
            </p>
            <p className="info-activity-item">Today 09:11 AM</p>
          </div>
        </div>
        <div>
          <div className="image-recent-activity-item">img</div>
          <div>
            <p className="title-recent-activity-item">
              Added new Listing by Lisa Jones
            </p>
            <p className="info-activity-item">New Tokyo Apartment</p>
            <p className="info-activity-item">Today 19:43 AM</p>
          </div>
        </div>
        <div>
          <div className="image-recent-activity-item">img</div>
          <div>
            <p className="title-recent-activity-item">
              Edward Moore purchased Tpkyo Apartment
            </p>
            <p className="info-activity-item">
              Purchased Tokyo Apartment for $ 44300
            </p>
            <p className="info-activity-item">Today 09:23 AM</p>
          </div>
        </div>
        <div>
          <div className="image-recent-activity-item">img</div>
          <div>
            <p className="title-recent-activity-item">
              New Meeting schedule by David Garcia
            </p>
            <p className="info-activity-item">Office meeting at June 2023</p>
            <p className="info-activity-item">Today 12:19 AM</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RightSidebar;
