import React, { useState, useEffect } from 'react';
import { Form, Input, Button, DatePicker, TimePicker, Space, Table, Popconfirm, message, Row,Col } from 'antd';
import { companyService, authenticationService } from "../../app/services";
import moment from 'moment';
import dayjs from 'dayjs';  // Make sure to add this line
import { Hidden } from '@mui/material';

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const CompanySettings = (props) => {
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [userAvailability, setUserAvailability] = useState(null);
  const [editingRecord, setEditingRecord] = useState(null);
  const [breakTimes, setBreakTimes] = useState([{ from: null, to: null }]);
  // const [breakTimes, setBreakTimes] = useState([
  //   { from: moment('08:00:00', 'HH:mm:ss'), to: moment('08:15:00', 'HH:mm:ss') },
  //   { from: moment('10:00:00', 'HH:mm:ss'), to: moment('10:30:00', 'HH:mm:ss') },
  // ]);

  useEffect(() => {
    // Fetch employees from the database when the component mounts
    fetchEmployeesFromDatabase();
  }, []);

  const fetchEmployeesFromDatabase = async () => {
    try {
      // Make an API call to fetch employees from the database
      const response = await companyService.getCompanyEmployees(props.company);
      const employeesWithKey = response.data.map((employee, index) => ({ ...employee, key: employee.PlanningId }));
      // Update the state with the fetched employees
      setEmployees(employeesWithKey);
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  useEffect(() => {
    form.validateFields(['name']); // Trigger validation on mount
  }, [form]);

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text, record) => (
        <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (text, record) => (
        <span>{text ? moment(text).format('YYYY-MM-DD') : ''}</span>
      ),
    },
    {
      title: 'Appointment Time',
      dataIndex: 'meetingTime',
      key: 'meetingTime',
    }, 
    {
      title: 'Break Times',
      dataIndex: 'breakTimes',
      key: 'breakTimes',
      render: (breakTimes) => (
        <div>
          {breakTimes && breakTimes.map((breakTime, index) => (
            <div key={index}>  
            {breakTime.from} - {breakTime.to}
             {/* {dayjs(breakTime.from).isValid() ? dayjs(breakTime.from).format('HH:mm') : 'Invalid date'} - 
                {dayjs(breakTime.to).isValid() ? dayjs(breakTime.to).format('HH:mm') : 'Invalid date'}  */}
            </div>
          ))}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleEditClick(record)}>
            Edit
          </Button>
          <Popconfirm
            title="Are you sure to delete this employee?"
            onConfirm={() => handleDeleteEmployee(record.planningId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger">Delete</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];


  const checkTypedText = async (_, value) => {
    if (!value) {
      return Promise.resolve();
    }

    try {
      const data = await authenticationService.searchUserByEmailAndNumber(value);

      if (data.error) {
        setUserAvailability('User is not available.');
        return Promise.reject('User is not available.');
      } else {
        setUserAvailability('User is available.');
        return Promise.resolve();
      }
    } catch (error) {
      console.error('Error checking user availability:', error);
      setUserAvailability('Error checking user availability.');
      return Promise.reject('Error checking user availability.');
    }
  };

// Function to convert breakTimes to moment objects
const convertBreakTimesToMoments = (breakTimes) => {
  return breakTimes.map(breakTime => ({
    from: moment(breakTime.from, 'HH:mm:ss')  ,//breakTime.from ?  moment(), 'HH:mm:ss') : null,
    to:  moment(breakTime.to, 'HH:mm:ss'),// ? moment(breakTime.to, 'HH:mm:ss') : null,
  }));
};

  const handleEditClick = (record) => {
    setEditingRecord(record);
      // Format date values before setting them in the form
  const formattedRecord = {
    ...record,
    startDate: moment(record.startDate,'YYYY-MM-DDTHH:mm:ss'),
    endDate: moment(record.endDate,'YYYY-MM-DDTHH:mm:ss'), 
    meetingTime: moment(record.meetingTime, 'HH:mm:ss')
  };    
    // Set break times separately
    record.breakTimes.forEach((bt, index) => {
      formattedRecord[`breakTimeFrom${index}`] = dayjs(bt.from, 'HH:mm:ss');
      formattedRecord[`breakTimeTo${index}`] = dayjs(bt.to, 'HH:mm:ss');
    });
  // ,
  // breakTimes: convertBreakTimesToMoments(record.breakTimes)

  console.log(formattedRecord.meetingTime);
  console.log(formattedRecord.breakTimes);
  form.setFieldsValue(formattedRecord);
  setBreakTimes(formattedRecord.breakTimes);
  //  form.setFieldsValue(record); // Set form fields with the data from the selected record
  };

  const onFinish = (values) => {

    {editingRecord ? handleEditEmployee(values) : handleAddEmployee(values)}
    console.log('Success:', values);
  };

  const transformBreakTimes = (data) => {    
    const breakTimes = [];
    for (const key in data) {
      if (key.startsWith('breakTimeFrom')) {
        const index = key.slice('breakTimeFrom'.length);
        const from = data[key];
        const to = data[`breakTimeTo${index}`];
  
        if (from && to) {
          breakTimes.push({ 
            from: from.format('HH:mm:ss'), 
            to: to.format('HH:mm:ss') 
          });
        }
      }
    }
  
    return breakTimes;
  };
  // const transformBreakTimes = (data) => {
  //   debugger;
  //   const breakTimes = [];
  
  //   for (const key in data) {
  //     if (key.startsWith('breakTimeFrom')) {
  //       const index = key.slice('breakTimeFrom'.length);
  //       const from = data[key];
  //       const to = data[`breakTimeTo${index}`];
  
  //       if (from && to) {
  //         breakTimes.push({ from, to });
  //       }
  //     }
  //   }
  
  //   return breakTimes;
  // };

  /// Manage employee
  const handleAddEmployee = (values) => {
    setEmployees([...employees, values]);

    // Update user profile
    companyService
      .addEmployeetoCompany(props.company, values.name, values.startDate, values.endDate, values.meetingTime.format('HH:mm:ss'),transformBreakTimes(values))
      .then((updatedProfile) => {
        // setProfile(updatedProfile);
        // form.setFieldsValue(updatedProfile);
      setEditingRecord(null); // Clear the editing state
        message.success(`The employee has been added successfully.`);
        form.resetFields();
      })
      .catch((error) => {
        console.error("Failed to update user profile:", error);
      });

    // form.resetFields();
  };

  const handleEditEmployee = (values) => {
    // Implement logic for editing an employee
  

    message.success(`Editing employee: ${values.name}`);
    companyService
      .modifyEmployeetoCompany(props.company, values.name, values.planningId,values.startDate, values.endDate, values.meetingTime.format('HH:mm:ss'),transformBreakTimes(values))
      .then((updatedProfile) => {
        // setProfile(updatedProfile);
         form.setFieldsValue(updatedProfile);
        message.success(`The employee has been modified successfully.`);
        // form.resetFields();
      })
      .catch((error) => {
        console.error("Failed to update user profile:", error);
      });
  };

  const handleDeleteEmployee = (employeeKey) => {
    setEmployees(employees.filter((employee) => employee.planningId !== employeeKey));
    companyService
      .deleteEmployeetoCompany(employeeKey)
      .then((updatedProfile) => {
        // setProfile(updatedProfile);
        // form.setFieldsValue(updatedProfile);
        message.success(`The employee has been deleted successfully.`);
      })
      .catch((error) => {
        console.error("Failed to update user profile:", error);
      });
  };

  // Function to handle input changes
  const handleInputChange = (index, event) => {
    const values = [...breaktimes];
    values[index].value = event.target.value;
    setFields(values);
  };

  // Function to handle adding a new field
  const handleAddField = () => {
    setBreakTimes([...breakTimes, { from: null, to: null }]);
  };

  // Function to handle time picker changes
  const handleTimeChange = (index, field, time) => {
    const newBreakTimes = [...breakTimes];
    newBreakTimes[index][field] = time.format('HH:mm:ss');
    setBreakTimes(newBreakTimes);
  };

  // Function to handle form submission
  const handleSubmit = (values) => {
    console.log('Form submitted:', values);
    // Process the data here
  };

  return (
    // style={{ maxWidth: 800, margin: 'auto', padding: 16 }}
    <div >
    <h1>Company Settings</h1>

    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off" 
      initialValues={breakTimes.reduce((acc, bt, index) => {
        acc[`breakTimeFrom${index}`] = bt.from;
        acc[`breakTimeTo${index}`] = bt.to;
        return acc;
      }, {})}
      style={{ backgroundColor: '#fff', padding: 16, borderRadius: 8, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' , maxWidth: 800, margin: 'auto'}}
    >
        <Form.Item
          label="Planning"
          name="planningId"
        >
          <Input type="hidden" />
        </Form.Item>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Email or phone number"
            name="name"
            rules={[
              // {
              //   required: true,
              //   message: 'Please input your email or phone number!',
              // },
              {
                validator: checkTypedText,
              },
            ]}
          >
            <Input placeholder="Start typing to search users" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[
              {
                required: true,
                message: 'Please input the contract start date!',
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} defaultValue={moment().isSameOrAfter(moment(form.getFieldValue('startDate')), 'day') ? null : moment()}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[
              {
                required: true,
                message: 'Please input the contract end date!',
              },
            ]}
          >
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="meetingTime"
        label="Appointment Time"
        rules={[
          {
            required: true,
            message: 'Please input the appointment duration time!',
          },
        ]}
      >
        <TimePicker
          style={{ width: '100%' }}
          format="HH:mm:ss"
        />
      </Form.Item>
      {
            breakTimes.map((breakTime, index) => (
              <div key={index}>
          
                      <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name={`breakTimeFrom${index}`}
                              label={`Break Time From ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select your break time!',
                                },
                              ]}
                            >
                              <TimePicker
                                style={{ width: '100%' }}
                                format="HH:mm:ss"
                                onChange={(newTime) => handleTimeChange(index, 'from', newTime)}
                              /> 
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item
                              name={`breakTimeTo${index}`}
                              label={`Break Time To ${index + 1}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select your break time!',
                                },
                              ]}
                            >
                              <TimePicker
                                style={{ width: '100%' }}
                                format="HH:mm:ss"
                                onChange={(newTime) => handleTimeChange(index, 'to', newTime)}
                              />
                            </Form.Item>
                            </Col>
                            </Row>
              </div>
            ))
       }
      <Button type="button" onClick={handleAddField}>
        Add Break
      </Button>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {editingRecord ? 'Update' : 'Submit'}
          </Button>
        </Form.Item>
    </Form>

    <div style={{ marginTop: 24, maxWidth: '95%' }}>
      <h2>Employee List</h2>
      <Table dataSource={employees} columns={columns} pagination={false} responsive/>
    </div>
  </div>
  );
};

export default CompanySettings;


                
// value={breakTime.to ? moment(breakTime.to, 'HH:mm'): null}