import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Button, Stack } from "@mui/material";
import { Hub } from "@mui/icons-material";
import { Row, Col, Breadcrumb, Layout, Menu, Typography, Tooltip } from "antd";
import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  PushpinOutlined,
} from "@ant-design/icons";

import {
  requestCalendars,
  receiveCalendar,
  addCalendar,
  updateCalendar,
  deleteCalendar,
} from "../../app/services/actions/calendarActions";
import {
  receiveSchedule,
  requestSchedules,
  addSchedule,
  updateSchedule,
  deleteSchedule,
} from "../../app/services/actions/scheduleActions";
import SignalRService from "../../app/services/signalRService";

import CalendarModal from "./Component/CalendarModal";
import Calendar from "./Schedule";
import CalendarBlock from "./Component/CalendarBlock";
import CalendarSmallBlock from "./Component/CalendarSmallBlock";
import CalendarSelectToggle from "./Component/CalendarSelectToggle";
import AddCalendarButton from "./Component/AddCalendarButton";

import { scheduleService, calendarService } from "../../app/services";

const { Content, Sider } = Layout;
const { Title } = Typography;

import "../../assets/styles/schedulestyles.css";
import "../dx.common.css";
import "../dx.light.css";
import useWindowResize from "../../helpers/useWindowResizeListener";
import useWindowResizeListener from "../../helpers/useWindowResizeListener";
import RightSidebar from "./Component/RightSidebar";

// const tempCalendars = [
//     {
//         id: '2022-12-05',
//         name: 'first',
//         description: 'first calendar',
//         color: '#b38bdc',
//         selected: true,
//     },
//     {
//         id: '2022-12-06',
//         name: 'second',
//         description: 'second calendar',
//         color: '#2ecc87',
//         selected: true,
//     },
//     {
//         id: '2021-10-13',
//         name: 'third',
//         description: 'third calendar',
//         color: '#47b2f7',
//         selected: false,
//     },
// ]
// const tempSchedules = [
//     {
//        id: 297,calendar_id: '2022-12-05',text:'tet',note: null,startDate:'2023-01-02T09:00:00.000Z',title:'test1',
//        color: '#b38bdc',
//        endDate:'2023-01-02T10:00:00.000Z'
//     }  ,

//     {
//        id: 298,calendar_id: '2021-10-13',text:'tet2',note: null,startDate:'2023-01-02T09:30:00.000Z',title:'test2',
//        color: '#47b2f7',
//        endDate:'2023-01-02T17:00:00.000Z'
//     }
// ]

const Schedule = (props) => {
  const [basicColor, setBasicColor] = useState("#4865F5");
  const [calendarModalData, setCalendarModalData] = useState(null);
  const [sideBarVisible, setSideBarVisible] = useState(null);
  const [addCalendarFormVisible, setAddCalendarFormVisible] = useState(null);

  const [calendars, setCalendars] = useState(props.calendars);
  const [schedules, setSchedules] = useState(props.schedules);
  const [activeKey, setActiveKey] = useState(null);

  const [forceLoadData, setForceLoadData] = useState(true);
  const { width } = useWindowResizeListener();

  useEffect(() => {
      const signalRService = SignalRService.getInstance();
    // const signalRService = new SignalRService();

    console.log("calendar use effect signalr");
    signalRService
      .startConnection()
      .then(() => {
        console.log("SignalR connection established.");

        // Subscribe to events
        signalRService.on("CreateCalendar", DispatchCreateCalendar);
        signalRService.on("UpdateCalendar", DispatchUpdateCalendar);
        signalRService.on("DeleteCalendar", DispatchDeleteCalendar);
        signalRService.on("CreateSchedule", DispatchCreateSchedule);
        signalRService.on("UpdateSchedule", DispatchUpdateSchedule);
        signalRService.on("DeleteSchedule", DispatchDeleteSchedule);
      })
      .catch((error) => {
        console.error("Error establishing SignalR connection:", error);
      });

    return () => {
      // Unsubscribe from events and stop the connection
      signalRService.off("CreateCalendar", DispatchCreateCalendar);
      signalRService.off("UpdateCalendar", DispatchUpdateCalendar);
      signalRService.off("DeleteCalendar", DispatchDeleteCalendar);
      signalRService.off("CreateSchedule", DispatchCreateSchedule);
      signalRService.off("UpdateSchedule", DispatchUpdateSchedule);
      signalRService.off("DeleteSchedule", DispatchDeleteSchedule);
      signalRService.stopConnection();
    };
  }, []);

  useEffect(() => {
   // 
    setCalendars(props.calendars);
    setSchedules(props.schedules);
  }, [props.calendars, props.schedules]);

  //#region Dispatch methods

  const DispatchCreateCalendar = (data) => {
    // 
    props.onUpdateCalendar(data);
    setForceLoadData(true);
  };

  const DispatchUpdateCalendar = (data) => {
    props.onUpdateCalendar(data);
    setForceLoadData(true);
  };
  const DispatchDeleteCalendar = (data) => {
    props.onDeleteCalendar(data);
    setForceLoadData(true);
  };
  const DispatchCreateSchedule = (data) => {
    props.onAddSchedule(data);
    //setSchedules(props.schedules);
    //setForceLoadData(true);
  };
  const DispatchUpdateSchedule = (data) => {
    props.onUpdateSchedule(data);
    setForceLoadData(true);
  };
  const DispatchDeleteSchedule = (data) => {
    props.onDeleteSchedule(data);
    setForceLoadData(true);    
    let tmpSchedules = [...schedules];
    tmpSchedules = tmpSchedules.filter((e) => e.id != data.id);
  };

  //#endregion

  // Example how to invoke method inside SignalR Hub
  //   const sendMessage = () => {
  //     // Invoke server-side hub method
  //     signalRService.invoke('methodName', 'message');
  //   };
  useEffect(() => {
    //setConnection( HubService.Get(connection));

    setActiveKey(0);
    let isCancelled = false;
    if (forceLoadData) {
      props.onRequestCalendars();
      props.onRequestSchedules();
      setCalendars(props.calendars);
      setSchedules(props.schedules);

      setForceLoadData(false);
      return;
    }
    return () => {
      isCancelled = true;
      // setForceLoadData(false);
    };
  }, [props.schedules, props.calendars, forceLoadData, schedules, calendars]);

  //#region Common

  const onCollapse = (collapsed) => {
    setSideBarVisible(!sideBarVisible);
  };

  const checkAllToggle = () => {
    var tmpCalendars = [...calendars];
    if (
      tmpCalendars.find((e) => e.selected == true) &&
      tmpCalendars.find((e) => e.selected == false)
    ) {
      tmpCalendars.forEach((calendar) => {
        calendar.selected = true;
      });
    } else if (tmpCalendars.find((e) => e.selected == true)) {
      tmpCalendars.forEach((calendar) => {
        calendar.selected = false;
      });
    } else {
      tmpCalendars.forEach((calendar) => {
        calendar.selected = true;
      });
    }
    setBasicColor("#4865F5");
    setCalendars(tmpCalendars);
  };

  const isAvailableEdit = () => {
    var showingDatas = calendars.filter((e) => e.selected == true);
    if (showingDatas.length != 1) {
      return false;
    } else {
      return true;
    }
  };

  //#endregion

  //#region Manage Calendars

  const addCalendar = (value) => {
    let newId = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    let calendar = { ...value, id: newId, isselected: true };
    console.log("add calendar data ...", calendar);
    calendarService.send(calendar);
    setAddCalendarFormVisible(false);
  };

  const editCalendar = (value) => {
    let newId = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'

    let calendar = { ...value, id: newId, isselected: true };
    var tmp = [];
    calendars.forEach((e) => {
      if (e.id == value.id) {
        tmp.push({ ...value, selected: true });
      } else {
        tmp.push(e);
      }
    });
    setCalendars(tmp);
    setBasicColor(value.color);
    setAddCalendarFormVisible(false);
    calendarService.update(calendar);
  };

  const deleteCalendar = (value) => {
    var tmpCalendars = calendars.filter((e) => e.id != value);
    setCalendars(tmpCalendars);
    calendarService.remove(value);
    // props.onDeleteCalendar(value);

    // Update new Calendar
    // connection.invoke(
    //     "DeleteCalendar",
    //     value
    // ).catch(err => console.error(err.toString()));
  };

  const clickCalendar = (CalendarId) => {
    var index = 0;
    let tempCalendars = [...props.calendars];

    tempCalendars.forEach((calendar) => {
      // Select the calendar using the calendar Id
      if (calendar.id == CalendarId) {
        calendar.selected = !calendar.selected;
      }

      // Define the color of the selected calendar
      if (calendar.selected) {
        if (index == 0) {
          setBasicColor(calendar.color);
        }
        index++;
        setActiveKey(calendar.id);
      }
    });

    // Set the basic Color
    if (index > 1 || index == 0) {
      setBasicColor("#2ecc87");
    }

    // Update calendar View
    setCalendars(tempCalendars);
  };

  const getCalendarData = () => {
    
    var result = [];
    props.calendars?.forEach((calendar) => {
      if (calendar.selected && props.schedules != null) {
        var tmpDatas = props.schedules.filter(
          (e) => e.calendarid == calendar.id
        );
        tmpDatas.forEach(function (schedule) {
          if (schedule.color == null) {
            schedule.color = calendar.color;
            schedule.backgroundColor = calendar.color;
          }
        });

        result = [...result, ...tmpDatas];
      }
    });

    
    return result;
  };

  const OpenCalendarModal = (type, id = null) => {
    if (type !== "new") {
      setCalendarModalData(calendars.find((e) => e.id === id));
    } else {
      console.log("..new");
      setCalendarModalData(null);
    }
    setAddCalendarFormVisible(true);
  };

  const hideModal = () => {
    console.log("close edit modal....");
    setCalendarModalData(null);
    setAddCalendarFormVisible(false);
  };
  //#endregion

  //#region Manage Schedules

  const addAppointment = (schedule) => {
    let newId = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
    let tmpSchedules = [...schedules];
    tmpSchedules.push({ ...schedule, id: newId, calendar_id: activeKey });
    scheduleService.send(schedule);
  };

  const updateAppointment = (schedule) => {
   // debugger;
    var tmpSchedules = [...schedules];
    scheduleService.update(schedule);
  };

  const deleteAppointment = (appointmentId) => {
    let tmpSchedules = [...schedules];
    tmpSchedules = tmpSchedules.filter((e) => e.id != appointmentId);

    scheduleService.remove(appointmentId);
    // setSchedules(tmpSchedules);

    // props.onDeleteSchedule(appointmentId);

    // // Update new Schedule
    // connection.invoke(
    //     "DeleteSchedule",
    //     appointmentId
    // ).catch(err => console.error(err.toString()));background: '#f0f6fc'
  };

  useEffect(() => {
    console.log(
      "selected item from left sider....(edit modal opened)",
      calendarModalData
    );
  }, [calendarModalData]);

  //#endregion
 
  return (
    <>
      <Layout className={"content-form"} style={{ minHeight: "100vh" }}>
        <Sider
          collapsed={sideBarVisible}
          onCollapse={onCollapse}
          className="schedule-left-sider"
          width={300}
          collapsedWidth={85}
          style={{
            backgroundColor: "transparent!important",
            background: "transparent",
            borderRight: "1px solid #001529",
          }}
        >
          <div
            className={`gx-w-100 side_bar_header ${
              sideBarVisible ? "gx-pl-0" : ""
            }`}
          >
            {!sideBarVisible && (
              <Title level={2} className={"side_bar_header"}>
                Filters
              </Title>
            )}
            {!sideBarVisible && <div className={"flex-grow-1"} />}
            <Tooltip
              title={sideBarVisible ? "Expend" : "Compact"}
              placement="right"
            >
              <Button onClick={onCollapse} className={"ng-bg-color"}>
                <PushpinOutlined style={{ fontSize: 24, color: basicColor }} />
              </Button>
            </Tooltip>
          </div>
          {calendars && (
            <>
              {!sideBarVisible ? (
                <div className={"gx-w-100 calendar-cats"}>
                  <Row>
                    <Col
                      span={18}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "23px",
                      }}
                    >
                      <Title level={5} className="title-left-sidebar">
                        Calendars
                      </Title>
                    </Col>
                    <Col span={4}>
                      <AddCalendarButton
                        openAddCalendarForm={() => {
                          OpenCalendarModal("new", null);
                        }}
                      />
                    </Col>
                  </Row>
                  {calendars &&
                    calendars.map((calendar) => (
                      <CalendarBlock
                        key={calendar.id}
                        data={calendar}
                        handleClick={clickCalendar}
                        handleDeleteCalendar={deleteCalendar}
                        handleEditCalendar={() => {
                          OpenCalendarModal("edit", calendar.id);
                        }}
                        large={sideBarVisible}
                        className="calendar-block"
                      />
                    ))}
                </div>
              ) : (
                <div className={"gx-w-100 gx-h-100 gx-d-flex"}>
                  <div className={"gx-w-100"}>
                    {/* <CalendarSelectToggle clickCheckAll={checkAllToggle} /> */}
                    <AddCalendarButton
                      openAddCalendarForm={() => {
                        OpenCalendarModal("new");
                      }}
                    />
                    {calendars &&
                      calendars.map((calendar) => (
                        <CalendarSmallBlock
                          data={calendar}
                          key={calendar.id}
                          handleClick={clickCalendar}
                          large={sideBarVisible}
                        />
                      ))}
                  </div>
                </div>
              )}
            </>
          )}
        </Sider>
        <Content className="calendar-main-content">
          {/* {calendars && activeKey && background: '#f0f6fc'
                     <> */}
          <Calendar
            key={activeKey}
            selectedCalendarschedules={getCalendarData()}
            handleEditCalendar={() => {
              OpenCalendarModal("edit");
            }}
            openAddCalendarForm={() => {
              OpenCalendarModal("new");
            }}
            calendars={calendars}
            editCalendarAvailablity={isAvailableEdit()}
            clickCalendar={clickCalendar}
            handleSubmit={addAppointment}
            handleDelete={deleteAppointment}
            updateAppointment={updateAppointment}
            basicColor={basicColor}
          />
          <div id="calendar-footer">Footer AREA</div>
          {/* </>
                     }  */}
        </Content>
        <RightSidebar />
        {addCalendarFormVisible && (
          <CalendarModal
            visible={addCalendarFormVisible}
            formdata={calendarModalData}
            handleSubmit={addCalendar}
            handleEditSubmit={editCalendar}
            closeModal={hideModal}
            handleClose={() => {
              setAddCalendarFormVisible(false);
            }}
          />
        )}
      </Layout>
      {/* <Footer />  */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    calendars: state.calendar.calendars,
    currentCalendar: state.calendar.currentCalendar,
    schedules: state.schedule.schedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCalendars: () => requestCalendars(dispatch),
    onReceiveCalendar: (Color, BgColor, Name, Id, selected) =>
      dispatch(receiveCalendar(Color, BgColor, Name, Id, selected)),
    onAddCalendar: (calendar) => dispatch(addCalendar(calendar)),
    onUpdateCalendar: (calendar) => dispatch(updateCalendar(calendar)),
    onDeleteCalendar: (calendarid) => dispatch(deleteCalendar(calendarid)),
    onRequestSchedules: () => requestSchedules(dispatch),
    onReceiveSchedule: (
      Name,
      description,
      Recurence,
      Category,
      Location,
      calendarId,
      id,
      Start,
      End,
      RoleGroupId
    ) =>
      dispatch(
        receiveSchedule(
          Name,
          description,
          Recurence,
          Category,
          Location,
          calendarId,
          id,
          Start,
          End,
          RoleGroupId
        )
      ),
    onAddSchedule: (schedule) => dispatch(addSchedule(schedule)),
    onUpdateSchedule: (schedule) => dispatch(updateSchedule(schedule)),
    onDeleteSchedule: (schedule) => dispatch(deleteSchedule(schedule)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
