import React, { useState } from 'react';
import './ContactForm.css';
import { Box, TextField, Button, Grid } from '@mui/material';
import {authenticationService} from "../../../../app/services"
import { useTranslation } from 'react-i18next';

// import { Box } from 'react-bootstrap-icons';

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    phoneNumber: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    authenticationService
      .contactUs(formData)
      .then(
        (user) => {
          alert(user);
        },
        (error) => {
          alert(error)
        }
      );
  };

  return (

    <div className="contact-form-container">
  <div className="get-in-touch">
    <h2>{t('HomePage.Footer_contact')}</h2>
    <p>{t('HomePage.Footer_contactFillForm')}</p>
    <p>Contact mail: <a href="mailto:contact@diarytree.com">contact@diarytree.com</a></p>
  </div>

  <Box
    component="form"
    sx={{
      '& .MuiTextField-root': { m: 1 },
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    }}
    noValidate
    autoComplete="off"
  >
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          id="name"
          label={t('HomePage.Common_name')}
          value={formData.name}
          name="name"
          fullWidth
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="surname"
          label={t('HomePage.Common_surname')}
          value={formData.surname}
          name="surname"
          fullWidth
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="phoneNumber"
          label={t('HomePage.Common_phoneNumber')}
          name="phoneNumber"
          value={formData.phoneNumber}
          fullWidth
          onChange={handleChange}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField
          id="email"
          label={t('HomePage.Common_email')}
          value={formData.email}
          onChange={handleChange}
          name="email"
          required
          fullWidth
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="subject"
          label={t('HomePage.Common_subject')}
          value={formData.subject}
          name="subject"
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="message"
          label={t('HomePage.Common_message')}
          name="message"
          multiline
          maxRows={4}
          rows="6"
          value={formData.message}
          onChange={handleChange}
          fullWidth
          required
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          className="formbutton"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Send
        </Button>
      </Grid>
    </Grid>
  </Box>
</div>

    // <div className="contact-form-container">
    //   <div className="get-in-touch">
    //     <h2>{t('HomePage.Footer_contact')}</h2>
    //     <p>{t('HomePage.Footer_contactFillForm')}</p>
    //     <p>Contact mail: <a href="mailto:contact@diarytree.com">contact@diarytree.com</a></p>
    //   </div>
    //       <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' },}}  noValidate autoComplete="off">   
    // <div className="form-row">
    //     <div className="form-group">
    //       <TextField id="name" label={t('HomePage.Common_name')} 
    //           value={formData.name}
    //           name="name"
    //           onChange={handleChange}/>
    //       </div>
    //       <div className="form-group">
            
    //       <TextField id="surname" label={t('HomePage.Common_surname')}   
    //           value={formData.surname}
    //           name="surname"
    //           onChange={handleChange}/>
    //       </div>
    //     </div>
    //     <div className="form-row">
    //       <div className="form-group">
    //         <TextField id="phoneNumber" label={t('HomePage.Common_phoneNumber')} 
    //           name="phoneNumber" 
    //           value={formData.phoneNumber}
    //           onChange={handleChange}/>
    //       </div>
    //       <div className="form-group">
                      
    //       <TextField id="email" label={t('HomePage.Common_email')}  
    //           value={formData.email}
    //           onChange={handleChange} 
    //           name="email" required/>
    //       </div>
    //     </div>
    //     <div className="form-group">
          
    //     <TextField id="subject" label={t('HomePage.Common_subject')}   style={{width:'100%'}}
    //          name="subject"
    //           value={formData.subject}
    //           onChange={handleChange}  required/>
    //     </div>
    //     <div className="form-group">
    //     <TextField
    //       id="message"  style={{width:'100%'}}
    //       label={t('HomePage.Common_message')}
    //         name="message"
    //       multiline
    //       maxRows={4}   value={formData.message}
    //       onChange={handleChange}
    //       rows="6"
    //       required
    //     />
    //     </div>
    //     <Button className="formbutton" variant="contained" color="primary" onClick={handleSubmit}>
    //     Send
    //   </Button>
    //     </Box>
    //  </div>
  );
};

export default ContactForm;
