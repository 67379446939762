import React, { useEffect, useState,useRef } from "react";
import moment from "moment";
import { connect } from "react-redux";
import {
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { Form, Row, Col, Input, message, Modal } from "antd";
import {
  SettingFilled,
  LeftCircleOutlined,
  RightCircleOutlined,
  PlusOutlined,
  MessageOutlined,
  BellOutlined,
} from "@ant-design/icons";

import { Scheduler } from "./Component/Scheduler";
import AppointmentForm from "./Component/AppointmentForm";
import MobileSidebar from "./Component/MobileSidebar";

const Calendar = (props) => {
  const [TestData, setTestData] = useState(props.selectedCalendarschedules);
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [newForm, setNewForm] = useState(false);
  const [startDay, setStartDay] = useState(0);
  const [scheduleView, setScheduleView] = useState("week");
  const [SelectedDate, setSelectedDate] = useState(new Date());
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");
  const [oldScheduleId, setOldScheduleId] = useState(null);
  const [view, setView] = React.useState("");
  const formRef = useRef();

  var MONDAY_INDEX = 1;
  useEffect(() => {
    if (props.schedules) {
      setTestData(props.schedules);
    }
  }, [props.schedules]);

  const onAppointmentUpdating = async (e) => {
    props.updateAppointment(e.newData);
  };

  // const onAppointmentClick = (e) => {
  //   setNewForm(false);
  //   setFormData({
  //     ...e.appointmentData,
  //     startTime: moment(e.appointmentData.startTime),
  //     endTime: moment(e.appointmentData.endTime),
  //     startDate: moment(e.appointmentData.startDate),
  //     endDate: moment(e.appointmentData.endDate),
  //   });
  // };

  const onAppointmentEditBtnClick = () => {
    setNewForm(false);
    setVisible(true);
  };

  const onAppointmentDeleteBtnClick = async (bookingId) => {
    props.handleDelete(bookingId);
  };

  // const AppointmentTooltipContainer = connectProps(AppointmentTooltipContainer, () => {

  //   return {
  //     onAppointmentEditBtnClick: onAppointmentEditBtnClick,
  //     onAppointmentDeleteBtnClick: onAppointmentDeleteBtnClick
  //   };
  // });
  // const AppointmentTooltip = async (AppointmentTooltipContainer) => {
  //   return {
  //     onAppointmentEditBtnClick: onAppointmentEditBtnClick,
  //     onAppointmentDeleteBtnClick: onAppointmentDeleteBtnClick
  //   };
  // };

  const hideModal = (context) => {

      if(context != null)
      {
        CreateSchedulerModal(context);
      }
      setVisible(false);  
    
    if (formRef.current) {
      formRef.current.resetForm();
    }
  };

  const handleSubmit = async (values) => {
    debugger;
    var submitData = { ...values };
    // submitData['startdate'] = submitData['startdate'].format('YYYY-MM-DD')  + ' ' +submitData['starttime'].format('HH:mm:ss');
    // submitData['enddate'] = submitData['enddate'].format('YYYY-MM-DD') + ' ' +submitData['endtime'].format('HH:mm:ss');

    if (newForm) {
      props.handleSubmit(submitData);
      
    } else {
      props.updateAppointment({newSchedule: values,oldScheduleId:oldScheduleId});
    }
    setVisible(false);
    setFormData({});
    
  };

  const handleDeleteSubmit = async (scheduleid) => {
    props.handleDelete(scheduleid);
    setVisible(false);
    
  };

  const onCellClick = (e) => {
    setNewForm(true);
    setVisible(false);
    setFormData({
      startDate: moment(e.cellData.startDate),
      endDate: moment(e.cellData.endDate),
      startTime: moment(e.cellData.startDate),
      endTime: moment(e.cellData.endDate),
    });
    setVisible(true);
  };

  const CreateSchedulerModal = (context) => {

    setNewForm(true);
    setVisible(false);

    let startDate = context.startDate;
    let endDate = context.endDate;
    if (startDate == null || startDate == "") {
      startDate = moment();

      endDate = moment().add(30, "minutes");
      // let currentDate = new Date();
      //  startDate = currentDate;//.toLocaleDateString('fr')+ 'T' + currentDate.toLocaleTimeString('fr');

      //  let currentEndDate = new Date();

      //  currentEndDate = new Date(currentEndDate.setMinutes ( currentEndDate.getMinutes() + 30 ));
      //  //currentEndDate.setMinutes ( currentDate.getMinutes() + 30 );
      //  endDate = currentEndDate;//.toLocaleDateString('fr')+ 'T' + currentEndDate.toLocaleTimeString('fr');
    }

    //let endDate = context.endDate != null?context.enddate: startDate.add('30','minutes');

    setFormData({
      // startDate: context.startDate != null?context.startDate:moment().format('YYYY/MM/DD'),
      // endDate:  context.endDate != null?context.endDate:moment().format('YYYY/MM/DD'),
      // startTime: context.startTime != null?context.startTime: moment().format('hh:mm'),
      // endTime: context.endTime != null?context.endTime: moment().add(30, 'minutes').format('hh:mm'),
      startDate: startDate, //context.startDate != null?context.startDate:moment().format('YYYY/MM/DD'),
      endDate: endDate, //context.endDate != null?context.endDate:moment().format('YYYY/MM/DD'),
      // startTime: context.startDate != null?startDate.toLocaleTimeString(): moment().format('hh:mm'),
      // endTime: context.endDate != null?endDate.toLocaleTimeString(): moment().add(30, 'minutes').format('hh:mm'),
      calendars: props.calendars,
    });
    setVisible(true);
  };

  const EditSchedulerModal = (context) => {
    if (context.id == null) {
      CreateSchedulerModal(context);
      return;
    }

    setNewForm(false);
    setVisible(false);


    let startDate = new Date(context.startDate);
    let endDate = new Date(context.endDate);

    setOldScheduleId(context.id);

    setFormData({
      id: context.id,
      title: context.title,
      description: context.description,
      companyemployeeid: context.companyemployeeid,
      companyemployeeservices: context.companyemployeeservices,
      companyid: context.companyid,
      calendarid: context.calendarid,
      location: context.location,
      startDate: context.startDate != null ? context.startDate : moment(), //.format('YYYY/MM/DD'),
      endDate:
        context.endDate != null ? context.endDate : moment().add(30, "minutes"), //.format('YYYY/MM/DD'),
      startTime:
        context.startDate != null ? startDate.toLocaleTimeString() : moment(), //.format('hh:mm'),
      endTime:
        context.endDate != null
          ? endDate.toLocaleTimeString()
          : moment().add(30, "minutes"), //.format('hh:mm'),
      calendars: props.calendars,
      participants: context.participants
    });
    setVisible(true);
  };

  const onCurrentDateChange = async (e) => {
    setSelectedDate(e);
  };

  const changeView = async (e) => {
    setScheduleView(e);
  };

  const getFilterData = (data, text) => {
    if (!RegExp.escape) {
      RegExp.escape = function (value) {
        // eslint-disable-next-line no-useless-escape
        return value.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, `\\$&`);
      };
    }
    var result = [];
    text = RegExp.escape(text);
    if (data) {
      data.map((e) => {
        var title = e.title ? e.title.toString() : "";
        if (title.search(text) != -1) {
          result.push(e);
        }
        return true;
      });
    }
    return result;
  };

  const renderDataCell = (itemData) => {
    
    if (scheduleView == "month") {
      if (moment(itemData.startDate).isSame(moment().format(), "day")) {
        return (
          <div
            style={{
              borderTop: `4px solid ${props.basicColor}`,
              backgroundColor: `${props.basicColor}c9`,
              color: "#fff",
            }}
          >
            {itemData.text}
          </div>
        );
      } else if (moment(itemData.startDate).isSame(moment().format(), "week")) {
        return (
          <div style={{ borderTop: `1px solid ${props.basicColor}` }}>
            {itemData.text}
          </div>
        );
      } else {
        return <div>{itemData.text}</div>;
      }
    } else {
      if (moment(itemData.startDate).isSame(moment().format(), "hour")) {
        return (
          <div style={{ borderTop: `4px solid ${props.basicColor}` }}>
            {itemData.text}
          </div>
        );
      } else if (
        moment(itemData.startDate).format("H") == moment().format("H")
      ) {
        return (
          <div style={{ borderTop: `1px solid ${props.basicColor}` }}>
            {itemData.text}
          </div>
        );
      } else {
        return <div>{itemData.text}</div>;
      } //style={{ width: '100px',  height: '50px',  top: '8px', zIndex: '999',    display: 'inline-flex' }} className={'gx-w-100 gx-text-right gx-d-flex'}
    }
  };

  //#region utils
  function getFirstWeekDate(date, firstDayOfWeek) {
    var delta = (date.getDay() - firstDayOfWeek + 7) % 7;
    var result = new Date(date);
    result.setDate(date.getDate() - delta);
    return result;
  }

  var formatCaptionByMonths = function (lastDate, firstDate) {
    var isDifferentMonthDates = firstDate.getMonth() !== lastDate.getMonth();
    var isDifferentYears = firstDate.getFullYear() !== lastDate.getFullYear();
    //var useShortFormat = isDifferentMonthDates || this.option("_useShortDateFormat");
    var lastDateText;
    var firstDateText;
    var returnDateText;

    if (isDifferentYears) {
      firstDateText = firstDate.getUTCDate(); //_date2.default.format(firstDate, getCaptionFormat(true));
      lastDateText = lastDate.getUTCDate(); //_date2.default.format(lastDate, getCaptionFormat(true))

      var monthNameFirst = firstDate.toLocaleString("default", {
        month: "long",
      });
      var monthNameLast = lastDate.toLocaleString("default", { month: "long" });

      var yearNameFirst = firstDate.getFullYear();
      var yearNameLast = lastDate.getFullYear();

      returnDateText =
        firstDateText +
        " " +
        monthNameFirst +
        " " +
        yearNameFirst +
        "-" +
        lastDateText +
        " " +
        monthNameLast +
        " " +
        yearNameLast;
    } else if (isDifferentMonthDates) {
      firstDateText = firstDate.getUTCDate(); //_date2.default.format(firstDate, getCaptionFormat(true));
      lastDateText = lastDate.getUTCDate(); //_date2.default.format(lastDate, getCaptionFormat(true))

      var monthNameFirst = firstDate.toLocaleString("default", {
        month: "long",
      });
      var monthNameLast = lastDate.toLocaleString("default", { month: "long" });

      var yearNameFirst = firstDate.getFullYear();

      returnDateText =
        firstDateText +
        " " +
        monthNameFirst +
        "-" +
        lastDateText +
        " " +
        monthNameLast +
        " " +
        yearNameFirst;
    } else {
      firstDateText = firstDate.getUTCDate(); //_date2.default.format(firstDate, getCaptionFormat(true));
      lastDateText = lastDate.getUTCDate(); //_date2.default.format(lastDate, getCaptionFormat(true))

      var monthNameFirst = firstDate.toLocaleString("default", {
        month: "long",
      });

      var yearNameFirst = firstDate.getFullYear();

      returnDateText =
        monthNameFirst +
        " " +
        firstDateText +
        "-" +
        lastDateText +
        "," +
        yearNameFirst;
    }

    return returnDateText.toUpperCase();
  };

  const changeCalendarDate = async (direction) => {
    var current = new Date(SelectedDate);

    switch (scheduleView) {
      case "day":
        current = new Date(
          SelectedDate.setDate(calendarDate.getDate() + direction)
        );
        break;
      case "week":
        current = new Date(
          SelectedDate.setDate(calendarDate.getDate() + 7 * direction)
        );
        break;
      case "month":
        current = new Date(
          SelectedDate.setMonth(calendarDate.getMonth() + direction)
        );
        break;
    }
    setCalendarDate(current);
  };

  var normalizeDateByWeek = function (date, currentDate) {
    var differenceInDays = dateUtils.getDatesInterval(date, currentDate, "day");
    var resultDate = new Date(date);
    if (differenceInDays >= 6) {
      resultDate = new Date(resultDate.setDate(resultDate.getDate() + 7));
    }
    return resultDate;
  };

  const getNextDate = function (direction) {
    var initialDate =
      arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null;
    var stepConfig = this._getConfig();
    var offset = stepConfig.duration * direction;
    var date = stepConfig.getDate(
      new Date(initialDate || this.option("date")),
      offset
    );
    return date;
  };

  const updateCurrentDate = function (direction) {
    const date = new Date(SelectedDate.setDate(SelectedDate.getDate() + 7));
    setSelectedDate(date);
  };

  //#endregion

  const showCurrentDateCaption = (date) => {
    switch (scheduleView) {
      case "day":
        var dayDateText = date.getUTCDate(); //_date2.default.format(firstDate, getCaptionFormat(true));

        var monthNameText = date.toLocaleString("default", { month: "long" });

        var yearNameText = date.getFullYear();

        return (
          dayDateText + " " + monthNameText.toUpperCase() + "," + yearNameText
        );

      case "week":
        // var firstDayOfWeek = startDay;//this.option("firstDayOfWeek");
        // var firstDayOfWeekIndex = 0;// (firstDayOfWeek) ? firstDayOfWeek : getDefaultFirstDayOfWeekIndex(shift);

        // var firstWeekDate = getFirstWeekDate(date, firstDayOfWeekIndex);

        // var lastWeekDate = new Date(firstWeekDate);
        // var intervalCount = 7;// this.option("intervalCount"); , margin: '10px auto auto 2em' ,color: 'rgb(150, 153, 158)'
        // lastWeekDate = new Date(lastWeekDate.setDate(lastWeekDate.getDate() + (intervalCount - 1)));

        // return formatCaptionByMonths(lastWeekDate, firstWeekDate);
        var monthNameText = SelectedDate.toLocaleString("default", {
          month: "long",
        }).toUpperCase();

        var yearNameText = SelectedDate.getFullYear();

        return monthNameText + "," + yearNameText;

      case "month":
        var monthNameText = SelectedDate.toLocaleString("default", {
          month: "long",
        }).toUpperCase();

        var yearNameText = SelectedDate.getFullYear();

        return monthNameText + "," + yearNameText;
    }
  };

  const handleChange = (event) => {
    changeView(event.target.value);
  };

  const renderAppointment = (model) => {
    return (
      <React.Fragment>
        <i>toto</i>
        <p>Ppipi</p>
      </React.Fragment>
    );
  };
  // style={{ backgroundColor:'#f0f6fc'}}

  return (
    <>
      <Row className={"gx-flex-sm-row gx-w-100 top-calendar-wrapper"}>
        {/* <Col span={24}  style={{backgroundColor:'red'}}>    style={{ borderBottom: 'solid 1px' }}, display: 'flex' zIndex: '999',zIndex: '999', 
          <Row > 
       */}
        <Col
          span={24}
          sm={{ span: 12 }}
          lg={{
            span: 11,
            offset: 3,
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            display: "inline-flex",
          }}
        >
          <MobileSidebar
            openAddCalendarForm={props.openAddCalendarForm}
            calendars={props.calendars}
            clickCalendar={props.clickCalendar}
          />
          <div style={{ display: "flex" }}>
            <Button
              type={"default"}
              className={"currentDate"}
              style={{
                background: "none",
                fontSize: "14px",
                height: "auto",
                color: "#333",
                border: "none",
                flex: "0px!important",
                margin: "5px",
              }}
            >
              {showCurrentDateCaption(calendarDate)}
            </Button>
            <Button
              variant="outlined"
              className="top-calendar-nav-button"
              style={{
                border: "none",
                color: "#2b3a41",
                display: "flex",
                justifyContent: "end",
                maxWidth: "40px",
                padding: "5px 10px 5px 0",
              }}
              startIcon={<LeftCircleOutlined style={{ margin: "0px" }} />}
              onClick={() => {
                changeCalendarDate(-1);
              }}
            />
            <Button
              variant="outlined"
              className="top-calendar-nav-button gx-mr-3"
              style={{
                border: "none",
                color: "#2b3a41",
                display: "flex",
                justifyContent: "start",
                width: "20px",
                maxWidth: "40px",
                padding: "5px 0 5px 10px",
              }}
              startIcon={<RightCircleOutlined style={{ margin: "0px" }} />}
              onClick={() => {
                changeCalendarDate(1);
              }}
            />
          </div>
        </Col>
        <Col
          span={24}
          sm={{
            span: 12,
          }}
          lg={{
            span: 10,
          }}
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Select
            id="demo-simple-select"
            className="top-calendar-grid-dropdown"
            value={scheduleView}
            label="View"
            onChange={handleChange}
            style={{
              width: "100px",
              height: "40px",
              backgroundColor: "White",
              margin: "5px",
              borderRadius: "7px",
            }}
          >
            <MenuItem value={"day"}>day</MenuItem>
            <MenuItem value={"week"}>week</MenuItem>
            <MenuItem value={"month"}>month</MenuItem>
          </Select>

          <Button
            variant="contained"
            className={"gx-mr-3 top-calendar-add-event-btn"}
            startIcon={<PlusOutlined />}
            onClick={() => {
              CreateSchedulerModal({ startDate: "", endDate: "" });
            }}
            style={{
              width: "130px",
              height: "40px",
              backgroundColor: "#4865F5",
              margin: "5px",
              fontSize: 11,
            }}
          >
            Add Event
          </Button>
          <Button
            variant="outlined"
            className="top-calendar-settings-btn"
            style={{ border: "none" }}
            startIcon={
              <SettingFilled style={{ fontSize: 24, color: "#bbbbbb" }} />
            }
            onClick={() => {
              if (props.editCalendarAvailablity) {
                props.handleEditCalendar();
              }
            }}
          />
        </Col>
      </Row>
      <Row>
        {props?.selectedCalendarschedules && (
          <Scheduler
            showCurrentTimeIndicator={true}
            dataCellRender={renderDataCell}
            dataSource={getFilterData(
              props.selectedCalendarschedules,
              filterText
            )}
            defaultCurrentView={scheduleView}
            defaultCurrentDate={SelectedDate}
            height={"80vh"}
            showAllDayPanel={true}
            //  onAppointmentClick={EditSchedulerModal}
            //onAppointmentEditBtnClick={EditSchedulerModal}
            onAppointmentFormOpening={EditSchedulerModal}
            //  onAppointmentFormOpening={EditSchedulerModal}
            // onCellClick={onCellClick}
            //  appointmentTooltipTemplate={Appointment}
            // appointmentTooltipComponent={AppointmentTooltip}
            // onCurrentDateChange={onCurrentDateChange}
            // onAppointmentUpdating={onAppointmentUpdating}
            firstDayOfWeek={startDay}
            currentView={scheduleView}
            currentDate={calendarDate}
            cellDuration={30}
          >
            {/* <AppointmentTooltip
            showCloseButton
            showOpenButton
          /> */}
          </Scheduler>
        )}
        {/* {formdata !== null && ( */}
        <AppointmentForm
          ref={formRef}
          key="appointmentForm"
          visible={visible}
          newForm={newForm}
          formdata={formData}
          setFormData={setFormData}
          handleSubmitForm={handleSubmit}
          handleDeleteSubmit={handleDeleteSubmit}
          closeModal={hideModal}
          onFinish={hideModal}
        />
        {/* )} */}
        {/* </Row>
        </Col> */}
      </Row>
    </>
  );
};

const onAppointmentFormOpening = (e) => {
  const { form } = e;
  let movieInfo = getMovieById(e.appointmentData.movieId) || {};
  let { startDate } = e.appointmentData;

  form.option("items", [
    {
      label: {
        text: "Movie",
      },
      editorType: "dxSelectBox",
      dataField: "movieId",
      editorOptions: {
        items: moviesData,
        displayExpr: "text",
        valueExpr: "id",
        onValueChanged(args) {
          movieInfo = getMovieById(args.value);

          form.updateData("director", movieInfo.director);
          form.updateData(
            "endDate",
            new Date(startDate.getTime() + 60 * 1000 * movieInfo.duration)
          );
        },
      },
    },
    {
      label: {
        text: "Director",
      },
      name: "director",
      editorType: "dxTextBox",
      editorOptions: {
        value: movieInfo.director,
        readOnly: true,
      },
    },
    {
      dataField: "startDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
        onValueChanged(args) {
          startDate = args.value;
          form.updateData(
            "endDate",
            new Date(startDate.getTime() + 60 * 1000 * movieInfo.duration)
          );
        },
      },
    },
    {
      name: "endDate",
      dataField: "endDate",
      editorType: "dxDateBox",
      editorOptions: {
        width: "100%",
        type: "datetime",
        readOnly: true,
      },
    },
    {
      dataField: "price",
      editorType: "dxRadioGroup",
      editorOptions: {
        dataSource: [5, 10, 15, 20],
        itemTemplate(itemData) {
          return `$${itemData}`;
        },
      },
    },
  ]);
};

const mapStateToProps = (state) => {
  return {
    calendars: state.calendar.calendars,
    currentCalendar: state.calendar.currentCalendar,
    schedules: state.schedule.schedules,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onRequestCalendars: () => requestCalendars(dispatch),
    onReceiveCalendar: (Color, BgColor, Name, Id, selected) =>
      dispatch(receiveCalendar(Color, BgColor, Name, Id, selected)),
    onSetCalendar: (calendar) => dispatch(setCalendar(calendar)),
    onRequestSchedules: () => requestSchedules(dispatch),
    onReceiveSchedule: (
      Name,
      description,
      Recurence,
      Category,
      Location,
      calendarId,
      id,
      Start,
      End,
      RoleGroupId
    ) =>
      dispatch(
        receiveSchedule(
          Name,
          description,
          Recurence,
          Category,
          Location,
          calendarId,
          id,
          Start,
          End,
          RoleGroupId
        )
      ),
  };
};
// export default Schedule;

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

{
  /* <div className={'gx-w-25 gx-h-0'} >
                <Button
                  className={'gx-mr-3'}
                  type={'default'}
                  icon={<LeftOutlined />}
                />
                <Button
                  type={'default'}
                  className={'view_button'}
                >Current Date</Button>
                <Button
                  className={'gx-mr-3'}
                  type={'default'}
                  icon={<RightOutlined />}
                ></Button>
              </div>
              <div className={'calendar_header gx-d-flex gx-w-75'}> */
}
{
  /* <div className={'gx-w-25 gx-h-0'} />
                <div className={'gx-w-100 gx-text-right gx-d-flex'}>
                  <Button
                    type={'default'}
                    className={'view_button'}
                    onClick={() => { changeView('day') }}
                    style={{ backgroundGridor: scheduleView == 'day' ? props.basicGridor : '', Gridor: scheduleView != 'day' ? '#96999e' : '#fff' }}
                  >Day</Button>
                  <Button
                    type={'default'}
                    className={'view_button'}
                    onClick={() => { changeView('week') }}
                    style={{ backgroundGridor: scheduleView == 'week' ? props.basicGridor : '', Gridor: scheduleView != 'week' ? '#96999e' : '#fff' }}
                  >Week</Button>
                  <Button
                    type={'default'}
                    className={'view_button'}
                    onClick={() => { changeView('month') }}
                    style={{ backgroundGridor: scheduleView == 'month' ? props.basicGridor : '', Gridor: scheduleView != 'month' ? '#96999e' : '#fff' }}
                  >Month</Button>
                  {/* <Button
                    type={'default'}
                    className={'view_button'}
                    onClick={() => { changeView('day') }}
                    style={{ backgroundGridor: scheduleView == 'day' ? props.basicGridor : '', Gridor: scheduleView != 'day' ? '#96999e' : '#fff' }}
                  >Daily</Button> 
                  <div className={'flex-gGrid-1'} />
                  <input className={'calendar_header_search gx-mr-3'} placeholder="Search by entering keywords" />
                  <Button
                    className={'gx-mr-3'}
                    type={'default'}
                    icon={<Plus style={{ fontSize: 24, Gridor: '#bbbbbb' }} />}
                    onClick={() => { openModal() }}
                  ></Button>
                  <Button
                    className={'gx-mr-3'}
                    type={'default'}
                    icon={<SettingFilled style={{ fontSize: 24, Gridor: '#bbbbbb' }} />}
                    onClick={() => {
                      if (props.editCalendarAvailablity) {
                        props.handleEditCalendar();
                      }
                    }}
                  ></Button>
                </div>
              </div>*/
}
