import axios from 'axios';
import { handleResponse, handleError } from './response';
import appConfig from '../../envConfig/config';
import { authenticationService } from '../authentication.service';
import { setToken,checkToken } from '../user.slice';

const getCurrentLanguage = () => {
  return localStorage.getItem('language') || 'en'; // Default to 'en' if not set
};

// Define your Òapi url from any source.
// Pulling from your .env file when on the server or from 172.17.0.4 when locally
//const BASE_URL = 'http://127.0.0.1:3333/api/v1'; 
// const BASE_URL = 'https://127.0.0.1:8001'; 
/** @param {string} resource */
const getAll = (resource) => {
    return axios
      .get(`${appConfig.api_url}/${resource}`, {
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          "Content-Type": "application/json; charset=utf-8", 'Authorization': 'Bearer ' + authenticationService.currentUserToken,
          'Accept-Language': getCurrentLanguage()
        }
      })
      .then(handleResponse)
      .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
  return axios
    .get(`${appConfig.api_url}/${resource}/${id}`, {
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        "Content-Type": "application/json; charset=utf-8", 'Authorization': 'Bearer ' + authenticationService.currentUserToken,
        'Accept-Language': getCurrentLanguage()
      }
    })
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
  const isFormData = model instanceof FormData;
  if (authenticationService?.currentUserToken != null) {


    const headers = {
      'Authorization': `Bearer ${authenticationService.currentUserToken || ''}`,
      'Accept-Language': getCurrentLanguage()
    };

    if (!isFormData) {
      headers['Content-Type'] = 'application/json';
    }

    return axios
      .post(`${appConfig.api_url}/${resource}`, model, {
        mode: 'cors',
        cache: 'no-cache',
        headers: headers
      })
      .then(handleResponse)
      .catch(handleError);
  }
  else {
    return axios
      .post(`${appConfig.api_url}/${resource}`, model)
      .then(handleResponse)
      .catch(handleError);
  }

};

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model) => {
  return axios
    .put(`${appConfig.api_url}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, model) => {
  return axios
    .patch(`${appConfig.api_url}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id) => {
  return axios
    .delete(`${appConfig.api_url}/${resource}`, id)
    .then(handleResponse)
    .catch(handleError);
};

export const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove,
};