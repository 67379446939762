// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import appConfig from './app/envConfig/config';
// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import translationEN from './translation/en.json';
// import translationFR from './translation/fr.json';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';


// const resources = {
//   en: {
//     translation: translationEN,
//   },
//   fr: {
//     translation: translationFR,
//   },
// };



i18n
  .use(Backend) // Enable backend plugin
  .use(LanguageDetector) // Detect user's preferred language
  .use(initReactI18next) // Pass i18n instance to React
  .init({
    debug: true,
    fallbackLng: 'en',
    backend: {
      // Configuration for fetching translations
      loadPath: appConfig.api_url + '/translations/{{lng}}', // URL to your backend API
      allowMultiLoading: false, // Set true if you want to load multiple namespaces simultaneously
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'], // Language detection order
      caches: ['cookie'], // Where to cache the selected language
    },
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    react: {
      bindI18n: 'languageChanged',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: false,
    },
  });

// i18n
//   // .use(Backend)
//   // .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     debug:true,
//     resources,
//     fallbackLng: 'en',
//     detection: {
//       order: ['navigator', 'htmlTag', 'path', 'subdomain'],
//       caches: ['cookie'],
//     },
//     interpolation: {
//       escapeValue: false,
//     },
//     react: {
//       bindI18n: 'languageChanged',
//       bindI18nStore: '',
//       transEmptyNodeValue: '',
//       transSupportBasicHtmlNodes: true,
//       transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
//       useSuspense: false,
//     },
//   });

 export default i18n;